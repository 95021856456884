// Copyright 2021 SeekOps Inc.
/**
 * make it possible to reference a color palette by a specific string
 */
export enum ColorPaletteName {
  primaryred = "primaryred",
  oxbloodred = "oxbloodred",
  lightgray = "lightgray",
  galaxygray = "galaxygray",
  charcoalgray = "charcoalgray",
  jewelgreen = "jewelgreen",
}

/**
 * make it possible to reference a color by name
 */
export enum ColorName {
  seekopsBlack = "#333333",
  seekopsWhite = "#ffffff",
  seekopsRed = "#770102",
  seekopsBlue = "#098BEB",
  seekopsBlue2 = "#0B3ADE",
  seekopsDarkBlue = "#1E2336",
  seekopsInfoBlue = "#154b7e",
  seekopsInfoBlueLight = "#9BC6EE",
  seekopsGreen = "#157e48",
}
