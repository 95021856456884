// Copyright 2021 SeekOps Inc.
/**
 * define Material UI themes
 */

// third-party
import { createTheme } from "@mui/material/styles";

// first-party
import { CustomTheme, CustomThemeOptions } from "./theme.interfaces";
import ThemeConfigDark, { chartsDarkPalette } from "./theme.dark";
import ThemeConfigLight, { chartsLightPalette } from "./theme.light";

export enum ThemeType {
  dark = "dark",
  light = "light",
  seekops = "seekops",
  brutal = "brutal",
}

/**
 *
 * @param themeType
 */
const getTheme = (themeType: string): CustomTheme => {
  let themeConfig: CustomThemeOptions;
  let chartPalette: string[];

  if (themeType === "light") {
    themeConfig = ThemeConfigLight;
    chartPalette = chartsLightPalette;
  } else {
    themeConfig = ThemeConfigDark;
    chartPalette = chartsDarkPalette;
  }
  if (themeConfig && themeConfig.direction) {
    // set the body element's direction attribute accordingly
    document.body.dir = themeConfig.direction;
  }
  const themeInstance = createTheme({ ...themeConfig });
  const customThemeInstance: CustomTheme = {
    ...themeInstance,
    chartPalette: chartPalette,
  };
  return customThemeInstance;
};

export default getTheme;
