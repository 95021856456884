// Copyright 2021 SeekOps Inc.
// third-party
import { Reducer } from "redux";

// first-party
import {
  AuthenticationState,
  authenticationActionTypes,
  AuthenticationActionTypes,
} from "./authentication.types";

/**
 * set the authentication state to whatever was in the local storage.
 * Local storage is cleared whenever a log out occurs or a token refresh fails
 */
const getInitialAuthenticationState = (): AuthenticationState => {
  let state: AuthenticationState = {
    token: null,
    refreshToken: null,
    refreshExpiry: null,
    isAuthenticated: false,
    userIDNumber: null,
    userID: null,
    userNameFirst: null,
    userNameLast: null,
    companyID: null,
    companyName: null,
    companies: [],
    loading: false,
    eulaAccepted: false,
    error: null,
    signedOutAutomatically: false,
    MSALAccessToken: null,
  };
  // check if local storage has languageSelectorState
  let existingToken = localStorage.getItem("JWT");
  let existingRefreshToken = localStorage.getItem("JWTr");
  let existingRefreshExpiry = localStorage.getItem("SODA_refresh_expiry");
  let existingUserID = localStorage.getItem("SODA_user");
  let existingCompanyID = localStorage.getItem("SODA_company");
  let existingCompanyName = localStorage.getItem("SODA_companyName");
  let existingUserDetails = localStorage.getItem("SODA_user_details");
  let existingEulaAccepted = localStorage.getItem("SODA_EULA");
  let existingCompanies = localStorage.getItem("SODA_companies");

  if (existingToken) {
    state.token = existingToken;
  }
  if (existingRefreshToken) {
    state.refreshToken = existingRefreshToken;
  }
  if (existingToken && existingRefreshToken) {
    state.isAuthenticated = true;
  }
  if (existingRefreshExpiry) {
    state.refreshExpiry = existingRefreshExpiry;
  }
  if (existingUserID) {
    state.userID = existingUserID;
  }
  if (existingCompanyID) {
    try {
      state.companyID = parseInt(existingCompanyID);
    } catch (err) {
      console.error("FAILED TO PARSE EXISTING COMPANY ID ", err);
    }
  }

  if (existingCompanyName) {
    state.companyName = existingCompanyName;
  }

  if (existingCompanies) {
    state.companies = JSON.parse(existingCompanies);
  }

  if (existingUserDetails) {
    let existingUserDetailsObj = JSON.parse(existingUserDetails);

    if (existingUserDetailsObj && existingUserDetailsObj.user) {
      if (existingUserDetailsObj.user.first) {
        state.userNameFirst = existingUserDetailsObj.user.first;
      }
      if (existingUserDetailsObj.user.last) {
        state.userNameLast = existingUserDetailsObj.user.last;
      }
      if (existingUserDetailsObj.user.idNumber) {
        state.userIDNumber = existingUserDetailsObj.user.idNumber;
      }
    }
  }
  if (existingEulaAccepted) {
    state.eulaAccepted = existingEulaAccepted === "true";
  }

  return state;
};

// Type-safe initialState!

export const initialAuthenticationState: AuthenticationState =
  getInitialAuthenticationState();

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings
// on the reducer side, everything will remain type-safe.
const authenticationReducer: Reducer<
  AuthenticationState,
  AuthenticationActionTypes
> = (state = initialAuthenticationState, action) => {
  switch (action.type) {
    case authenticationActionTypes.SET_TOKEN: {
      return { ...state, token: action.token };
    }

    case authenticationActionTypes.SET_REFRESH_TOKEN: {
      return { ...state, refreshToken: action.refreshToken };
    }

    case authenticationActionTypes.TOKEN_REFRESH_SUCCESS: {
      localStorage.setItem("JWT", action.token);
      return {
        ...state,
        isAuthenticated: true,
        refreshToken: action.refreshToken,
        token: action.token,
      };
    }

    case authenticationActionTypes.TOKEN_VERIFY_CURRENT_SUCCESS: {
      return {
        ...state,
        token: action.token,
        refreshToken: action.refreshToken,
        isAuthenticated: true,
      };
    }

    case authenticationActionTypes.SIGN_OUT: {
      localStorage.removeItem("JWT");
      localStorage.removeItem("JWTr");
      localStorage.removeItem("SODA_refresh_expiry");
      localStorage.removeItem("SODA");
      localStorage.removeItem("SODA_user_details");
      localStorage.removeItem("SODA_EULA");
      localStorage.removeItem("SODA_user");
      localStorage.removeItem("SODA_company");
      localStorage.removeItem("SODA_companyName");
      localStorage.removeItem("SODA_companies");
      localStorage.removeItem("SODA_abilities");
      localStorage.removeItem("SODA_emissions_preferences");
      localStorage.removeItem("SODA_flightpath_preferences");

      // go to root of app and don't allow to go back
      if (window.location.pathname !== "/") {
        window.location.replace("/");
      }

      return getInitialAuthenticationState();
    }

    case authenticationActionTypes.INITIALIZE: {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }

    case authenticationActionTypes.SET_MSAL_ACCESS_TOKEN: {
      return {
        ...state,
        MSALAccessToken: action.MSALAccessToken,
      };
    }

    case authenticationActionTypes.INITIALIZE_FAILED: {
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        refreshToken: null,
        refreshExpiry: null,
        userID: null,
        companyID: null,
        companyName: null,
        error: "INITIALIZATION ERROR",
        loading: false,
      };
    }

    case authenticationActionTypes.SET_COMPANY: {
      localStorage.setItem("SODA_company", String(action.companyID));
      localStorage.setItem("SODA_companyName", action.companyName);

      return {
        ...state,
        companyID: action.companyID,
        companyName: action.companyName,
      };
    }

    case authenticationActionTypes.INITIALIZE_SUCCESSFUL: {
      localStorage.setItem("JWT", action.token);
      localStorage.setItem("JWTr", action.refreshToken);
      localStorage.setItem("SODA_user", action.userID);
      localStorage.setItem("SODA_company", String(action.companyID));
      localStorage.setItem("SODA_companyName", String(action.companyName));
      localStorage.setItem("SODA_refresh_expiry", action.refreshExpiry);
      let userDetails = {
        user: {
          id: action.userID,
          idNumber: action.userIDNumber,
          first: action.userNameFirst,
          last: action.userNameLast,
          companyName: action.companyName,
          companyID: action.companyID,
        },
      };
      localStorage.setItem("SODA_companies", JSON.stringify(action.companies));
      localStorage.setItem("SODA_user_details", JSON.stringify(userDetails));
      localStorage.setItem("SODA_EULA", String(action.eulaAccepted));
      return {
        ...state,
        isAuthenticated: true,
        token: action.token,
        refreshToken: action.refreshToken,
        refreshExpiry: action.refreshExpiry,
        userIDNumber: action.userIDNumber,
        userID: action.userID,
        companyID: action.companyID,
        companyName: action.companyName,
        companies: action.companies,
        userNameFirst: action.userNameFirst,
        userNameLast: action.userNameLast,
        error: null,
        loading: false,
        eulaAccepted: action.eulaAccepted, // existing value when logging in
      };
    }

    // updated the store state when a user explicitly accepts the EULA
    case authenticationActionTypes.SET_ACCEPTANCE: {
      localStorage.setItem("SODA_EULA", String(action.isAccepted));
      return {
        ...state,
        eulaAccepted: action.isAccepted,
      };
    }

    // can be used to display special message on log in page when signed out
    case authenticationActionTypes.SET_SESSION_EXPIRED: {
      return {
        ...state,
        signedOutAutomatically: action.signedOutAutomatically,
      };
    }

    default:
      return state;
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export default authenticationReducer;
