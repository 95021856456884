// Copyright 2021 SeekOps Inc.
// react
import React from "react";

// third party
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

// first party
import EN_US from "./ResourceBundles/en_US.json";

interface PostQASummaryProps {
  totalSurveys: number;
  surveysFailed: number;
  projectName?: string;
}

i18next.addResourceBundle("en-US", "translation", EN_US);

const PostQASummary = (props: PostQASummaryProps) => {
  const { t } = useTranslation();

  /**
   *
   * @param surveysFailed
   * @param totalSurveys
   */
  const getFailurePercentage = (
    surveysFailed: number,
    totalSurveys: number
  ): number => {
    let percentage = 0;
    if (surveysFailed > 0 && totalSurveys > 0) {
      percentage = Math.round((surveysFailed / totalSurveys) * 100);
    }
    return percentage;
  };

  return (
    <Box sx={{ margin: "2rem" }} aria-label="post-qa-summary-root">
      <Card variant="outlined">
        <Typography
          variant="caption"
          component="h2"
          sx={{
            margin: "1rem",
          }}
        >
          {t("msg.post-qa.summary-caption")}
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("msg.post-qa.project")}</TableCell>
                <TableCell>{t("msg.post-qa.total-surveys")}</TableCell>
                <TableCell>{t("msg.post-qa.failed-count")}</TableCell>
                <TableCell>{t("msg.post-qa.failed-percentage")}</TableCell>
                <TableCell>{t("msg.post-qa.pending-count")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{props.projectName}</TableCell>
                <TableCell>{props.totalSurveys}</TableCell>
                <TableCell>{props.surveysFailed}</TableCell>
                <TableCell>
                  {getFailurePercentage(
                    props.surveysFailed,
                    props.totalSurveys
                  )}
                  %
                </TableCell>
                <TableCell>
                  {props.totalSurveys - props.surveysFailed}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Box>
  );
};

export default PostQASummary;
