// Copyright 2021 SeekOps Inc.
import { Component, createElement } from "react";

import i18next from "i18next";
import { withTranslation } from "react-i18next";
import { debounce } from "ts-debounce";

import {
  SliderAssociation,
  SliderInfoComponentProps,
  SliderInfoComponentState,
  SliderInfoViewProps,
} from "./SliderInfo.interfaces";
import { SliderInfoView } from "./SliderInfo.view";
import axiosInstance from "../../../AJAX";
import EN_US from "./ResourceBundles/en_US.json";
import { connect } from "react-redux";

/**
 *
 */
class SliderWithInfo extends Component<
  SliderInfoComponentProps,
  SliderInfoComponentState
> {
  _isMounted: boolean;

  state: SliderInfoComponentState = {
    value: this.props.initialValue,
    potentialAssociations: [],
    actualAssociations: [],
    actualAssociationsDisplay: [],
  };

  debounceOnChangeInternal = debounce(this.props.handleOnChangeInternal!, 100);

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    i18next.addResourceBundle("en", "translation", EN_US);
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.sliderAssociationsEndpoint) {
      this.updateSliderAssociations(this.props.sliderAssociationsEndpoint);
    }
  }

  componentDidUpdate(prevProps: SliderInfoComponentProps) {
    if (
      this._isMounted &&
      this.props.value &&
      this.props.value !== prevProps.value
    ) {
      this.setState(
        (
          prevState: SliderInfoComponentState,
          props
        ): SliderInfoComponentState => ({
          ...prevState,
          value: this.props.value,
        })
      );
    }

    if (
      this.props.sliderAssociationsEndpoint &&
      this.props.sliderAssociationsEndpoint !==
        prevProps.sliderAssociationsEndpoint
    ) {
      // this function does its own check for isMounted
      this.updateSliderAssociations(this.props.sliderAssociationsEndpoint);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   *
   * @param associationsEndpoint
   */
  updateSliderAssociations = (associationsEndpoint: string) => {
    if (associationsEndpoint) {
      axiosInstance
        //.get(this.props.potentialAssociationsURL)
        .get(associationsEndpoint)
        .then((response: any) => {
          if (response && response.data) {
            let associations = response.data.map(
              (association: any): SliderAssociation => {
                let mappedAssociation: SliderAssociation = {
                  name: "",
                  id: -1,
                };
                if (association.name) {
                  mappedAssociation.name = association.name;
                }
                if (association.id) {
                  mappedAssociation.id = association.id;
                }
                return mappedAssociation;
              }
            );
            if (this._isMounted) {
              this.setState(
                (
                  prevState: SliderInfoComponentState,
                  props
                ): SliderInfoComponentState => ({
                  ...prevState,
                  potentialAssociations: associations,
                })
              );
            }
          }
        });
    }
  };

  debounceAssociationsOnChange = debounce((value, associations) => {
    // write the values to redux
    this.props.setSliderAssociatedValues(
      JSON.stringify(value), // map key
      associations // string [] of associations (name - id)
    );
  }, 100);

  updateValue = (value: any) => {
    this.setState(
      (
        prevState: SliderInfoComponentState,
        props
      ): SliderInfoComponentState => ({
        ...prevState,
        value: value,
      })
    );

    if (this.props.handleOnChangeInternal) {
      this.debounceOnChangeInternal(value);
    }

    this.debounceAssociationsOnChange(value, this.state.actualAssociations);
  };

  setAssociations = (associations: SliderAssociation[]) => {
    if (this._isMounted) {
      this.setState(
        (
          prevState: SliderInfoComponentState,
          props
        ): SliderInfoComponentState => ({
          ...prevState,
          actualAssociations: associations,
        })
      );
      // update redux
      this.props.setSliderAssociatedValues(
        JSON.stringify(this.state.value),
        associations
      );
    }
  };

  render() {
    let label = this.props.sliderAssociationsMessageLabel
      ? this.props.t(this.props.sliderAssociationsMessageLabel)
      : "";
    const sliderInfoViewProps: SliderInfoViewProps = {
      color: this.props.color,
      label: this.props.label,
      isLabelOn: this.props.isLabelOn,
      description: this.props.description,
      value: this.state.value,
      actualAssociationsDisplay: this.state.actualAssociationsDisplay,
      min: this.props.min,
      max: this.props.max,
      step: this.props.step,
      closable: this.props.closable,
      sliderAssociationsMessageLabel: label,
      potentialAssociations: this.state.potentialAssociations,
      handleOnCloseClick: this.props.handleOnCloseClick,
      handleOnChange: this.props.handleOnChange,
      handleOnChangeInternal: this.updateValue,
      t: this.props.t,
      setAssociations: this.setAssociations,
      setSliderAssociatedValues: this.props.setSliderAssociatedValues,
    };

    return createElement(SliderInfoView, { ...sliderInfoViewProps });
  }
}

// slice of state to pass to container through props
const mapStateToProps = (state: any) => {
  return {
    sliderAssociationsEndpoint:
      state.fluxPlaneConfig.sliderAssociationsEndpoint,
    sliderAssociationsMessageLabel:
      state.fluxPlaneConfig.sliderAssociationsMessageLabel,
  };
};

// actions that should be dispatched
const mapDispatchToProps = (dispatch: any) => {
  return {
    setSliderAssociatedValues: (
      interval: string,
      sliderAssociations: SliderAssociation[]
    ) => {
      return dispatch({
        type: "@@fluxPlaneConfig/SET_SLIDER_VALUES_ASSOCIATIONS",
        interval: interval,
        sliderAssociations: sliderAssociations,
      });
    },
  };
};

const TranslatedSliderWithInfo = withTranslation()(SliderWithInfo);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslatedSliderWithInfo);
