// Copyright 2021 SeekOps Inc.
// react
import React from "react";

// third-party
// import createPlotlyComponent from "react-plotly.js/factory";
import { Button, Chip, Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Data } from "plotly.js";

// first-party
import "./Chart-omni.view.scss";
import { ChartViewType } from "../Chart.enums";
import { ChartOmniViewProps } from "./ChartType.interfaces";
import SliderWithInfo from "../../../SharedComponents/SliderWithInformation/SliderInfo.container";

// const Plotly = require("plotly.js/lib/core");
// // Load in the trace types for only those required
// Plotly.register([
//   require("plotly.js/lib/pie"),
//   require("plotly.js/lib/barpolar"),
//   require("plotly.js/lib/scatter"),
//   require("plotly.js/lib/bar"),
// ]);
// const Plot = createPlotlyComponent(Plotly);

import Plot from "react-plotly.js";

/**
 *
 *
 * @param {any[]} valuesOfSliders
 * @param {string[]} labels
 * @returns {JSX.Element[]}
 */
const getSliders = (
  valuesOfSliders: number[][],
  labels: string[],
  props: ChartOmniViewProps
): JSX.Element[] => {
  let sliders: JSX.Element[] = [];
  let colorPalette = props.theme.chartPalette;
  let color: string;
  let dataKey: string;
  let label: string = "";

  valuesOfSliders.forEach((element, index) => {
    //Initial data plot, should be immutable

    dataKey = index + "";
    if (props.valuesOfSliders) {
      label =
        props.valuesOfSliders[index][0] +
        " - " +
        props.valuesOfSliders[index][1];
    }
    color = colorPalette[index]
      ? colorPalette[index]
      : colorPalette[index - colorPalette.length];
    sliders.push(
      <div className="slider" key={dataKey}>
        <Paper className="paper-slider">
          <SliderWithInfo
            color={color}
            label={label}
            description="TODO: We need a description for all these sliders! and a non snake_case name"
            initialValue={element}
            step={1}
            min={0}
            max={props.dataLength!}
            closable={true}
            handleOnCloseClick={() => {
              props.handleCloseSlider!(index);
            }}
            handleOnChangeInternal={(value: any) => {
              props.handleSliderChange!(value, index);
            }}
          />
        </Paper>
      </div>
    );
  });
  return sliders;
};

/**
 *
 * @param props
 */
const ChartOmniView = (props: ChartOmniViewProps) => {
  const style = { width: "100%", height: "500px" };
  if (props.hideActions) {
    style.height = "400px";
  }
  const plotConfig: any = {
    displayModeBar: !props.hideActions,
    displaylogo: false,
    responsive: true,
    staticPlot: false,
  };

  if (props.chartViewType === ChartViewType.scattersWithSlider) {
    let multiChartsData: Data[][] = props.multiData!;
    if (multiChartsData.length > 0) {
      let charts: JSX.Element[] = [];
      let chips: JSX.Element[] = [];

      let sliders = getSliders(props.valuesOfSliders!, props.labels, props);

      multiChartsData.forEach((chartData: Data[], index: number) => {
        chips.push(
          <div className="chip" key={props.labels[index]}>
            <Chip
              className={props.selectedPlotIndex === index ? "active" : ""}
              label={props.labels[index]}
              onClick={(event) => {
                props.handleRadioChange!(index);
              }}
            />
          </div>
        );
        if (props.selectedPlotIndex === index) {
          charts.push(
            <div key={props.labels[index] + index}>
              <div className="plots">
                <Paper>
                  <Plot
                    data={chartData}
                    layout={props.layout}
                    useResizeHandler={true}
                    style={style}
                    config={plotConfig}
                    className="chart-view"
                  />
                </Paper>
              </div>
              <div className="bottom-drawer-content">
                <div className="add-range">
                  <Button
                    variant="contained"
                    onClick={() => {
                      props.handleAddSlider!();
                    }}
                  >
                    <AddIcon /> Measurement Range
                  </Button>
                </div>
                <div className="sliders">{sliders}</div>
              </div>
            </div>
          );
        }
      });

      return (
        <div className="charts-sliders-view" aria-label={props.testAttribute}>
          <div className="chips-group">{chips}</div>
          {charts}
        </div>
      );
    }

    return <div></div>;
  } else {
    return (
      <div aria-label={props.testAttribute}>
        <Plot
          data={props.data!}
          layout={props.layout}
          useResizeHandler={true}
          style={style}
          config={plotConfig}
          className="chart-view"
        />
      </div>
    );
  }
};

export default ChartOmniView;
