// Copyright 2021 SeekOps Inc.
import React, { Suspense } from "react";

// third-party
import { useLocation } from "react-router";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector, useDispatch } from "react-redux";

// first-party
import "./AppBar.scss";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import AppTitleLogo from "../UI/AppTitleLogo/AppTitleLogo";
import { Box, IconButton } from "@mui/material";
import NotificationCenter from "../NotificationCenter";
import AsyncSideBar from "../SideBar";
import { RootState } from "../../store";
import CompanySwitcher from "../CompanySwitcher";

const AppToolBar = (props: any) => {
  const companies = useSelector(
    (state: RootState) => state.authentication.companies
  );

  const authenticatedCompanyId = useSelector(
    (state: RootState) => state.authentication.companyID
  );
  const authenticatedCompanyName = useSelector(
    (state: RootState) => state.authentication.companyName
  );

  const MSALToken = useSelector(
    (state: RootState) => state.authentication.MSALAccessToken
  );

  const { location, mode } = props;

  let authPathRegex =
    /(^\/\s*$)|(\/authentication\/.*\s*)|(\/authentication\s*$)/i;

  const isAuthenticatedFromStore = useSelector(
    (state: any) => state.authentication.isAuthenticated
  );

  const isOpenFromStore = useSelector((state: any) => state.sideBar.isOpen);

  const dispatch = useDispatch();

  const showSideBar = (flag: boolean) => {
    dispatch({
      type: "@@sideBar/SET_IS_OPEN",
      isOpen: flag,
    });
  };

  let locationMatch = location.pathname.match(authPathRegex);
  if (
    locationMatch &&
    location.pathname === locationMatch[0] &&
    mode !== "decorative"
  ) {
    return null;
  }

  return (
    <div className="menu-app-bar">
      <AppBar
        position="static"
        color="primary"
        className="menu-app-bar"
        data-testid="app-bar"
        aria-label="app-bar"
      >
        <Toolbar
          className="tool-bar"
          data-testid="toolbar"
          sx={{ display: "flex" }}
        >
          <Box sx={{ display: "flex" }}>
            <IconButton
              edge="start"
              className="menu-button"
              color="primary"
              aria-label="Menu"
              onClick={() => {
                showSideBar(!isOpenFromStore);
              }}
            >
              <MenuIcon />
            </IconButton>
            {isAuthenticatedFromStore && <NotificationCenter />}
          </Box>

          <AppTitleLogo />

          {/* for users authententicated with MSAL, display ability to switch companies */}
          {MSALToken &&
            authenticatedCompanyId !== null &&
            authenticatedCompanyId > -1 &&
            authenticatedCompanyName && (
              <Box sx={{ display: "flex", color: "white", zIndex: 1 }}>
                <Suspense fallback={<>Loading Company...</>}>
                  <CompanySwitcher
                    currentCompanyId={authenticatedCompanyId}
                    currentCompanyName={authenticatedCompanyName}
                    companies={companies}
                  />
                </Suspense>
              </Box>
            )}

          {isOpenFromStore && (
            <Suspense fallback={<>Loading Side bar...</>}>
              <AsyncSideBar />
            </Suspense>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

function withLocation(Component: any) {
  return (props: any) => {
    return <Component {...props} location={useLocation()} />;
  };
}

const MenuWithLocation = withLocation(AppToolBar);

export default MenuWithLocation;
