// Copyright 2021 SeekOps Inc.
// React
import { createRef, forwardRef, useCallback, useEffect, useState } from "react";

// Third-party
import MaterialTable, {
  Action,
  Column,
  MTableToolbar,
  MTableBodyRow,
  Query,
  QueryResult,
} from "@material-table/core";
import { merge } from "lodash/fp";
import Search from "@mui/icons-material/Search";
import SaveAlt from "@mui/icons-material/SaveAlt";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Check from "@mui/icons-material/Check";
import Clear from "@mui/icons-material/Clear";
import FilterList from "@mui/icons-material/FilterList";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import Remove from "@mui/icons-material/Remove";
import { useTranslation } from "react-i18next";

// First-party
import axiosInstance from "../../../AJAX";
import "./Table.scss";
import { getDataSourceURL } from "../../../global";
import { TableContainerProps, TableConfig } from "./Table.interfaces";
import i18next from "i18next";
import TableActions from "./TableActions";

import AR_SA from "./ResourceBundles/ar_SA.json";
import ZH_CN from "./ResourceBundles/zh_CN.json";
import EN_US from "./ResourceBundles/en_US.json";
import FR_FR from "./ResourceBundles/fr_FR.json";
import IT_IT from "./ResourceBundles/it_IT.json";
import PT_BR from "./ResourceBundles/pt_BR.json";
import RU_RU from "./ResourceBundles/ru_RU.json";
import ES_MX from "./ResourceBundles/es_MX.json";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import getTheme from "../../../config/theme";
import { ColorName } from "../../../config/theme/theme.enums";

const TableContainer = (props: TableContainerProps) => {
  const { t } = useTranslation();

  let currentThemeType: any = useSelector(
    (state: any) => state.theme.themeType
  );

  // state
  const [tableColumns, setTableColumns] = useState<Column<any>[]>([]);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [title, setTitle] = useState<string>("");
  const [endpoint, setEndpoint] = useState<string>("");
  const [tableConfig, setTableConfig] = useState<TableConfig>({});
  const [detailPanel, setDetailPanel] = useState<any>();
  const [customToolbar, setCustomToolbar] = useState<JSX.Element | undefined>(
    undefined
  );
  const tableRef: any = createRef();

  let flowRateUnitFromStore: any = useSelector(
    (state: any) => state.preferences.userUnits.flowRateUnit
  );

  interface Filter {
    column: {
      field: string;
    };
    value: string;
  }

  /**
   * uses the i18next translater provided in props through HOC to get
   * the currently selected language's translations
   *
   * @param props
   * @return material table localization object with translated values
   */
  const getTableLocalization = () => {
    // set translation function from i18next

    return {
      body: {
        emptyDataSourceMessage: t("msg.table.body.emptyDataSourceMessage"),
        addTooltip: t("msg.table.body.addTooltip"),
        deleteTooltip: t("msg.table.body.deleteTooltip"),
        editTooltip: t("msg.table.body.editTooltip"),
        filterRow: {
          filterTooltip: t("msg.table.body.filterRow.filterTooltip"),
        },
        editRow: {
          deleteText: t("msg.table.body.editRow.deleteText"),
          cancelTooltip: t("msg.table.body.editRow.cancelTooltip"),
          saveTooltip: t("msg.table.body.editRow.saveTooltip"),
        },
      },
      grouping: {
        placeholder: t("msg.table.grouping.placeholder"),
      },
      header: {
        actions: t("msg.table.header.actions"),
      },
      pagination: {
        labelDisplayedRows: t("msg.table.pagination.labelDisplayedRows"),
        labelRowsPerPage: t("msg.table.pagination.labelRowsPerPage"),
        firstAriaLabel: t("msg.table.pagination.firstAriaLabel"),
        firstTooltip: t("msg.table.pagination.firstTooltip"),
        previousAriaLabel: t("msg.table.pagination.previousAriaLabel"),
        previousTooltip: t("msg.table.pagination.previousTooltip"),
        nextAriaLabel: t("msg.table.pagination.nextAriaLabel"),
        nextTooltip: t("msg.table.pagination.nextTooltip"),
        lastAriaLabel: t("msg.table.pagination.lastAriaLabel"),
        lastTooltip: t("msg.table.pagination.lastTooltip"),
      },
      toolbar: {
        addRemoveColumns: t("msg.table.toolbar.addRemoveColumns"),
        nRowsSelected: t("msg.table.toolbar.nRowsSelected"),
        showColumnsTitle: t("msg.table.toolbar.showColumnsTitle"),
        showColumnsAriaLabel: t("msg.table.toolbar.showColumnsAriaLabel"),
        exportTitle: t("msg.table.toolbar.exportTitle"),
        exportAriaLabel: t("msg.table.toolbar.exportAriaLabel"),
        exportName: t("msg.table.toolbar.exportName"),
        searchTooltip: t("msg.table.toolbar.searchTooltip"),
        searchPlaceholder: t("msg.table.toolbar.searchPlaceholder"),
      },
    };
  };

  /**
   *
   */
  const getData = useCallback(
    (query: Query<any>): Promise<QueryResult<any>> => {
      const getFilterString = (filters: Filter[]) => {
        let filterString = "";

        filters.forEach((filter) => {
          filterString += "&" + filter.column.field + "=" + filter.value;
        });

        return filterString;
      };

      /**
       *
       * @param query
       * @param endpoint
       * @returns
       */
      const getDataURLWithParams = (
        query: Query<any>,
        endpoint: string
      ): string => {
        let dataURLWithParams;

        if (endpoint.includes("?")) {
          dataURLWithParams = getDataSourceURL(endpoint) + "&paginated=true";
        } else {
          dataURLWithParams = getDataSourceURL(endpoint) + "?paginated=true";
        }

        let page = query.page + 1;
        let sortColumnField = "";
        let sortDirection: "desc" | "" = "";

        // add pagination params
        dataURLWithParams += `&page=${page}`;
        dataURLWithParams += `&page_size=${query.pageSize}`;

        // collect sorting params if needed
        if (query.orderByCollection && query.orderByCollection.length) {
          let sortColumn = query.orderByCollection[0];
          let orderBy = sortColumn.orderBy;
          if (props.tableActions && props.tableActions.length) {
            // if an actions field is included (account for it by decrementing)
            orderBy--;
          }
          if (sortColumn.sortOrder && tableColumns.length >= orderBy) {
            sortColumnField = String(tableColumns[orderBy].field);
            if (sortColumn.orderDirection === "desc") {
              sortDirection = "desc";
            } else {
              sortDirection = "";
            }
          }
        }
        // append sorting params
        if (sortColumnField) {
          dataURLWithParams += `&sort=${sortColumnField}`;
          if (sortDirection === "desc") {
            dataURLWithParams += `&desc=true`;
          }
        }
        // add search param
        if (query.search) {
          dataURLWithParams += `&search=${query.search}`;
        }
        // add filters
        if (query.filters) {
          dataURLWithParams += getFilterString(query.filters as Filter[]);
        }

        //add site id
        // if (window.location.href.includes("sites") && siteIdFromStore !== -1) {
        //   dataURLWithParams += `&sites=${siteIdFromStore}`;
        // }

        return dataURLWithParams;
      };

      interface IObjectWithId {
        [key: string]: any;
        id: { value: number; [key: string]: any };
      }

      /**
       *
       * @param objects
       */
      const logDuplicateIds = (objects: IObjectWithId[]) => {
        const duplicates: { [key: string]: number[] } = {};
        objects.forEach((obj, index) => {
          const idValue = obj.id.value;
          if (!duplicates[idValue]) {
            duplicates[idValue] = [];
          }
          duplicates[idValue].push(index);
        });

        const duplicateKeys = Object.keys(duplicates).filter(
          (key) => duplicates[key].length > 1
        );

        if (duplicateKeys.length > 0) {
          console.groupCollapsed("DUPLICATES FOUND:");
          duplicateKeys.forEach((key) => {
            console.group(`id: ${key}`);
            console.info(`indices: ${duplicates[key].join(", ")}`);
            console.groupEnd();
          });
          console.groupEnd();
        }
      };

      /**
       *
       * @param query
       * @returns
       */
      const getRemoteData = (
        query: Query<any>,
        endpoint: string
      ): Promise<QueryResult<any>> => {
        return new Promise((resolve, reject) => {
          let url = getDataURLWithParams(query, endpoint);
          axiosInstance
            .get(url)
            .then(({ data }) => {
              if (data.data) {
                logDuplicateIds(data.data);
              }
              resolve({
                data: data.data,
                page: data.page - 1,
                totalCount: data.total,
              });
            })
            .catch((error) => {
              console.error(error);
              reject();
            });
        });
      };

      return getRemoteData(query, endpoint);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [endpoint, props.tableActions, tableColumns]
  );

  const getTableIcons = () => {
    const icons = {
      //Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Check: forwardRef<any>((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef<any>((props, ref) => <Clear {...props} ref={ref} />),
      //Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
      DetailPanel: forwardRef<any>((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      //Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef<any>((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef<any>((props, ref) => (
        <FilterList {...props} ref={ref} />
      )),
      FirstPage: forwardRef<any>((props, ref) => (
        <FirstPage {...props} ref={ref} />
      )),
      LastPage: forwardRef<any>((props, ref) => (
        <LastPage {...props} ref={ref} />
      )),
      NextPage: forwardRef<any>((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      PreviousPage: forwardRef<any>((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
      )),
      ResetSearch: forwardRef<any>((props, ref) => (
        <Clear {...props} ref={ref} />
      )),
      Search: forwardRef<any>((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef<any>((props, ref) => (
        <ArrowUpward {...props} ref={ref} />
      )),
      ThirdStateCheck: forwardRef<any>((props, ref) => (
        <Remove {...props} ref={ref} />
      )),
    };
    return icons;
  };

  /**
   *
   * @param newTableColumns
   * @returns
   */
  const getView = (newTableColumns: Column<any>[]) => {
    let actions = props.tableActions ? props.tableActions : [];
    let dividedActions: Action<any>[] = props.dividedTableActions
      ? props.dividedTableActions
      : [];
    let tableColumns: Column<any>[] = [];

    if (
      actions &&
      actions.length &&
      newTableColumns &&
      newTableColumns.length
    ) {
      let actionColumn: Column<any> = {
        title: t("msg.table.actions"),
        filtering: false,
        sorting: false,
        render: (rowData: any) => {
          return (
            <TableActions
              actions={actions}
              dividedActions={dividedActions}
              rowData={rowData}
            ></TableActions>
          );
        },
      };
      tableColumns = [actionColumn].concat(newTableColumns);
    } else if (newTableColumns) {
      // no action sbut table columns were given
      tableColumns = newTableColumns;
    }
    return (
      <div
        style={{ maxWidth: "100%" }}
        className={`ui-table-root ${currentThemeType}-mode`}
      >
        {/* remote data version of table */}
        {endpoint && tableColumns && tableColumns.length > 0 && (
          <ThemeProvider theme={getTheme(currentThemeType)}>
            <MaterialTable
              tableRef={tableRef}
              localization={getTableLocalization()}
              icons={getTableIcons()}
              options={tableConfig}
              columns={tableColumns || []}
              data={getData}
              title={title}
              components={{
                Container: (props: any) => <div>{props.children}</div>,
                ...props.tableComponents,
                Toolbar: (props: any) => (
                  <>
                    <MTableToolbar {...props} />
                    {customToolbar}
                  </>
                ),
                Row: (props: any) => {
                  // display the id of the object that is the basis of the row data;
                  return (
                    <MTableBodyRow
                      {...props}
                      key={props?.data?.id?.value}
                      id={props?.data?.id?.value}
                    />
                  );
                },
              }}
              detailPanel={detailPanel}
            />
          </ThemeProvider>
        )}

        {/* version of table where data is passed in as props */}
        {tableRows &&
          tableRows.length > 0 &&
          tableColumns &&
          tableColumns.length > 0 && (
            <MaterialTable
              localization={getTableLocalization()}
              icons={getTableIcons()}
              options={tableConfig}
              columns={tableColumns || []}
              data={tableRows}
              title={title}
              components={{
                Container: (props: any) => <div>{props.children}</div>,
                ...props.tableComponents,
                Toolbar: (props: any) => (
                  <>
                    <MTableToolbar {...props} />
                    {customToolbar}
                  </>
                ),
                Row: (props: any) => {
                  // display the id of the object that is the basis of the row data;
                  return (
                    <MTableBodyRow
                      {...props}
                      key={props?.data?.id?.value}
                      id={props?.data?.id?.value}
                    />
                  );
                },
              }}
              detailPanel={detailPanel}
            />
          )}
      </div>
    );
  };

  useEffect(() => {
    // add languages support
    i18next.addResourceBundle("ar", "translation", AR_SA);
    i18next.addResourceBundle("en", "translation", EN_US);
    i18next.addResourceBundle("es", "translation", ES_MX);
    i18next.addResourceBundle("fr", "translation", FR_FR);
    i18next.addResourceBundle("it", "translation", IT_IT);
    i18next.addResourceBundle("pt", "translation", PT_BR);
    i18next.addResourceBundle("ru", "translation", RU_RU);
    i18next.addResourceBundle("zh", "translation", ZH_CN);
  }, []);

  // update the state from props
  useEffect(() => {
    // define a default config to be used in conjunction with
    // any config provided
    const defaultTableConfig = {
      search: true,
      exportButton: false,
      exportAllData: false,
      filtering: true,
      emptyRowsWhenPaging: false,
      pageSize: 50,
      getRowId: (rowData: any) => {
        let rowId = "row-";
        if (rowData?.id?.value) {
          rowId += rowData.id.value;
        } else {
          rowId += new Date().getTime();
        }
        console.log("@@@@@@@@@@ rowId", rowId);
        return rowId;
      },
      pageSizeOptions: [50, 100, 200],
      rootStyle: {
        color:
          currentThemeType === "dark"
            ? ColorName.seekopsWhite
            : ColorName.seekopsBlack,
        fill:
          currentThemeType === "dark"
            ? ColorName.seekopsWhite
            : ColorName.seekopsBlack,
        backgroundColor:
          currentThemeType === "dark" ? "#303030" : ColorName.seekopsWhite,
        "&.table-actions": {
          color:
            currentThemeType === "dark"
              ? ColorName.seekopsWhite
              : ColorName.seekopsBlack,
          fill:
            currentThemeType === "dark"
              ? ColorName.seekopsWhite
              : ColorName.seekopsBlack,
        },
      },
      cellStyle: {
        color:
          currentThemeType === "dark"
            ? ColorName.seekopsWhite
            : ColorName.seekopsBlack,
        fill:
          currentThemeType === "dark"
            ? ColorName.seekopsWhite
            : ColorName.seekopsBlack,
        backgroundColor:
          currentThemeType === "dark" ? "#303030" : ColorName.seekopsWhite,
        fontFamily: "Roboto, Arial, sans-serif",
        fontWeight: "300",
      },
      headerStyle: {
        color: ColorName.seekopsInfoBlueLight,
        fill: ColorName.seekopsInfoBlueLight,
        backgroundColor:
          currentThemeType === "dark" ? "#303030" : ColorName.seekopsWhite,
        fontFamily: "Roboto, Arial, sans-serif",
        lineHeight: "2rem",
        fontSize: "1.5rem",
        fontWeight: "300",
      },
      filterCellStyle: {
        color:
          currentThemeType === "dark"
            ? ColorName.seekopsWhite
            : ColorName.seekopsBlack,
        fill:
          currentThemeType === "dark"
            ? ColorName.seekopsWhite
            : ColorName.seekopsBlack,
        backgroundColor:
          currentThemeType === "dark" ? "#303030" : ColorName.seekopsWhite,
        fontFamily: "Roboto, Arial, sans-serif",
        fontWeight: "300",
      },
      rowStyle: {
        color:
          currentThemeType === "dark"
            ? ColorName.seekopsWhite
            : ColorName.seekopsBlack,
        fill:
          currentThemeType === "dark"
            ? ColorName.seekopsWhite
            : ColorName.seekopsBlack,
        backgroundColor:
          currentThemeType === "dark" ? "#303030" : ColorName.seekopsWhite,
        fontFamily: "Roboto, Arial, sans-serif",
        fontWeight: "300",
      },
    };

    // make sure component is still mounted before attempting to modify state
    if (props.tableconfig) {
      setTableConfig(merge(defaultTableConfig, props.tableconfig));
    } else {
      setTableConfig(defaultTableConfig);
    }
  }, [props.tableconfig, currentThemeType]);

  useEffect(() => {
    if (props.detailPanel) {
      setDetailPanel(props.detailPanel);
    } else {
      setDetailPanel(undefined);
    }
  }, [props.detailPanel]);

  useEffect(() => {
    // title of table
    setTitle(props.title);
  }, [props.title]);

  useEffect(() => {
    // columns
    if (props.tableColumns) {
      setTableColumns(props.tableColumns ? props.tableColumns : []);
    } else if (props.getTableColumns) {
      setTableColumns(props.getTableColumns());
    }
  }, [props]);

  useEffect(() => {
    if (props.customToolbar) {
      setCustomToolbar(props.customToolbar);
    }
  }, [setCustomToolbar, props.customToolbar]);

  useEffect(() => {
    // data
    if (props.endpoint) {
      // use remote data
      setEndpoint(props.endpoint);
    } else if (props.tableRows && props.tableRows.length) {
      // use collection of data
      setTableRows(props.tableRows);
    }
  }, [props.endpoint, props.tableRows]);

  useEffect(() => {
    if (flowRateUnitFromStore) {
      tableRef?.current?.onQueryChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowRateUnitFromStore]);
  return getView(tableColumns);
};

export default TableContainer;
