// Copyright 2021 SeekOps Inc.
/**
 * Declaration of Notification Banner Types.
 * Please do not modify, only add (Open-Close Principle)
 * #SOLID-Principles
 *
 * @export
 * @enum {number}
 */
export enum NotificationType {
  warning = "warning",
  info = "info",
  success = "success",
  error = "error",
  default = "default"
}
