// Copyright 2021 SeekOps Inc.

// react
import { useEffect } from "react";

import { Card, CardContent, Typography, Dialog, Divider } from "@mui/material";
import Paper from "@mui/material/Paper";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

// first-party
import "./Authentication.view.scss";
import AppBar from "../AppBar/AppBar";
import SSOLoginButton from "../SignIn/SSOLoginButton";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useTranslation } from "react-i18next";

import smoke from "./backgrounds/smoke.jpg";

import EN_US from "./ResourceBundles/en_US.json";
import AR_SA from "./ResourceBundles/ar_SA.json";
import ES_MX from "./ResourceBundles/es_MX.json";
import ZH_CN from "./ResourceBundles/zh_CN.json";
import i18next from "i18next";
import { AuthenticationLegacy } from "./Authentication.legacy";

/**
 * Presents the visual aspect of authenticating a user
 *
 * @param props - values and logic provided by a parent component
 */
const Authentication = () => {
  const { t } = useTranslation();

  const signedOutAutomatically = useSelector(
    (state: RootState) => state.authentication.signedOutAutomatically
  );

  const authenticationView = (
    <div className="login-view" data-test="component-authentication-view">
      <div
        className="login-splash"
        style={{
          backgroundImage: `url(${smoke})`,
        }}
      >
        <div className="login-splash-overlay" />
      </div>

      <Dialog
        aria-labelledby="simple-dialog-title"
        open={true}
        className="login-view-dialog"
      >
        <div className="login-welcome">
          <AppBar
            mode="decorative"
            isAuthenticated={false}
            className="login-welcome-bar"
          />
          <Card className="login-welcome-card" role="main">
            <CardContent>
              <Typography
                variant="body1"
                component="h1"
                id="simple-dialog-title"
              >
                {t("msg.login.welcome")}
              </Typography>

              {/* display message whenever automatically logged out */}
              {signedOutAutomatically && (
                <Paper elevation={3}>
                  <Typography
                    variant="body2"
                    component="div"
                    className="notice"
                  >
                    <WarningRoundedIcon />
                    <span>{t("msg.login.session-expired")}</span>
                  </Typography>
                </Paper>
              )}
              <br />
              <AuthenticationLegacy />
              <br />
              <Divider></Divider>
              <br />
              <SSOLoginButton></SSOLoginButton>
            </CardContent>
          </Card>
        </div>
      </Dialog>
    </div>
  );

  useEffect(() => {
    i18next.addResourceBundle("en", "translation", EN_US);
    i18next.addResourceBundle("ar", "translation", AR_SA);
    i18next.addResourceBundle("es", "translation", ES_MX);
    i18next.addResourceBundle("zh", "translation", ZH_CN);
  }, []);

  return authenticationView;
};

export default Authentication;
