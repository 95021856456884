// Copyright 2021 SeekOps Inc.
import i18next from "i18next";
import i18n from "../../config/i18n";
import {
  languageSelectorStoreActionTypes,
  languageSelectorStoreState,
} from "./languageSelectorStore.types";

const getInitialState = (): languageSelectorStoreState => {
  let state = { locale: "en-US", dir: "ltr" };
  // check if local storage has languageSelectorState
  let existingLanguageSelectorStateString = localStorage.getItem("SODA_i18n");
  if (existingLanguageSelectorStateString) {
    let existingLanguageSelectorState = JSON.parse(
      existingLanguageSelectorStateString
    );
    if (existingLanguageSelectorState) {
      state = existingLanguageSelectorState;
    }
  } else {
    localStorage.setItem("SODA_i18n", JSON.stringify(state));
  }
  return state;
};

export const initalLanguageSelectorState: languageSelectorStoreState =
  getInitialState();

const languageSelectorStoreReducer = (
  state = initalLanguageSelectorState,
  action: any
) => {
  switch (action.type) {
    case languageSelectorStoreActionTypes.SET_LOCALE: {
      i18n.changeLanguage(action.locale);
      // set language direction on DOM
      document.body.dir = i18next.dir(action.locale);

      localStorage.setItem(
        "SODA_i18n",
        JSON.stringify({
          locale: action.locale,
          dir: action.dir,
        })
      );
      return {
        ...state,
        locale: action.locale,
        dir: action.dir,
      };
    }
    case languageSelectorStoreActionTypes.SET_DIR: {
      return {
        ...state,
        dir: action.dir,
      };
    }
    default:
      return state;
  }
};

export default languageSelectorStoreReducer;
