// Copyright 2021 SeekOps Inc.
// react
import React, { Fragment } from "react";

// third party
import { Tooltip } from "@mui/material";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

// first party
import EN_US from "./ResourceBundles/en_US.json";

import "./FPRStatuses.scss";

interface FPRStatusesProps {
  totalFPRs: number;
  failedFPRs: number;
}

i18next.addResourceBundle("en-US", "translation", EN_US);

/**
 *
 * @param props
 * @returns
 */
const FPRStatuses = (props: FPRStatusesProps) => {
  const { t } = useTranslation();

  /**
   *
   * @param totalFPRs
   * @param failedFPRs
   * @returns
   */
  const getFPRs = (totalFPRs: number, failedFPRs: number) => {
    let FPRs = [];
    for (let i = 0; i < totalFPRs; i++) {
      if (i + 1 <= failedFPRs) {
        FPRs.push(
          <span key={`$status-count-${i}`}>
            <FiberManualRecordIcon
              aria-label={"failed"}
              className="fpr-failed"
            />
          </span>
        );
      } else {
        FPRs.push(
          <span key={`$status-count-${i}`}>
            <FiberManualRecordIcon
              aria-label={"pending"}
              className="fpr-pending"
            />
          </span>
        );
      }
    }
    return FPRs;
  };

  let title = t("msg.post-qa.available-fprs", {
    avalableFPRs: props.totalFPRs - props.failedFPRs,
  });

  return (
    <div className="fpr-statuses">
      <Tooltip title={title} arrow>
        <div>
          <Fragment>{getFPRs(props.totalFPRs, props.failedFPRs)}</Fragment>
        </div>
      </Tooltip>
    </div>
  );
};

export default FPRStatuses;
