// Copyright 2021 SeekOps Inc.
// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define whichever naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the

import { FluxplaneRunLayerStatus } from "../../components/GeoMap/GeoMap.interfaces";

// convention of Redux's `@@INIT` action.
export enum surveyDialogActionTypes {
  SET_IS_OPEN = "@@surveyDialog/SET_IS_OPEN",
  SET_SURVEY_ID = "@@surveyDialog/SET_SURVEY_ID",
  SET_HISTOGRAM_DATA = "@@surveyDialog/SET_HISTOGRAM_DATA",
  SET_SURVEY_INFO = "@@surveyDialog/SET_SURVEY_INFO",
  SET_FLUXPLANE_RUN_IDS = "@@survey/SET_FLUXPLANE_RUN_IDS",
  SET_FLUXPLANE_RUN_STATUSES = "@@survey/SET_FLUXPLANE_RUN_STATUSES",
  SET_SURVEY_FLUXPLANE_RUN_DATA = "@@survey/SET_SURVEY_FLUXPLANE_RUN_DATA",
  SET_SURVEY_ACTIVE_FLUXPLANE_ID = "@@survey/SET_SURVEY_ACTIVE_FLUXPLANE_ID",
  SET_FLIGHTPATH_BY_TRIMS = "@@surveyDialog/SET_FLIGHTPATH_BY_TRIMS",
  SET_SITEID = "@@surveyDialog/SET_SITEID",
}

// Declare state types with `readonly` modifier to get compile time immutability
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface surveyDialogState {
  isOpen: boolean;
  surveyID: number;
  histogramData: any;
  fluxplaneRunIds: number[];
  fluxplaneRunStatuses: FluxplaneRunLayerStatus[];
  activeFluxplaneId: number;
  flightpathByTrims: boolean;
  pilot: string;
  serialNumber: string;
  startTime: string;
}

export default surveyDialogActionTypes;
