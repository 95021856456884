// Copyright 2021 SeekOps Inc.
// react
import React from "react";

// third-party
import createPlotlyComponent from "react-plotly.js/factory";
import { Button, Chip, Paper, Checkbox, Typography } from "@mui/material";

// first-party
import { ChartViewProps } from "./Chart.interfaces";
import { ChartViewType } from "./Chart.enums";
import SliderWithInfo from "../../SharedComponents/SliderWithInformation/SliderInfo.container";
import AddIcon from "@mui/icons-material/Add";
import "./Chart.view.scss";
import EN_US from "./ResourceBundles/en_US.json";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const Plotly = require("plotly.js/lib/core");
// Load in the trace types for only those required
Plotly.register([
  require("plotly.js/lib/pie"),
  require("plotly.js/lib/barpolar"),
  require("plotly.js/lib/scatter"),
  require("plotly.js/lib/bar"),
]);
const Plot = createPlotlyComponent(Plotly);

/**
 *
 *
 * @param {any[]} valuesOfSliders
 * @param {string[]} labels
 * @returns {JSX.Element[]}
 */
const getSliders = (
  valuesOfSliders: number[][],
  labels: string[],
  props: ChartViewProps
): JSX.Element[] => {
  let sliders: JSX.Element[] = [];
  let colorPalette =
    props.theme && props.theme.chartPalette ? props.theme.chartPalette : [];
  let color: string;
  let dataKey: string;
  let label: string = "";
  let minSlider: number;
  let maxSlider: number;
  let rankLength: number;

  valuesOfSliders.forEach((element, index) => {
    //Initial data plot, should be immutable
    minSlider = props.valuesOfSliders[index][0];
    maxSlider = props.valuesOfSliders[index][1];
    rankLength = props.indexToRank!.length - 1;
    dataKey = index + "";

    label =
      props.indexToRank![minSlider] +
      " - " +
      props.indexToRank![maxSlider > rankLength ? rankLength : maxSlider];
    color = colorPalette[index]
      ? colorPalette[index]
      : colorPalette[index - colorPalette.length];
    sliders.push(
      <div className="slider" key={dataKey}>
        <Paper className="paper-slider">
          <SliderWithInfo
            color={color}
            label={label}
            initialValue={element}
            step={1}
            min={0}
            max={props.dataLength}
            value={element}
            closable={true}
            handleOnCloseClick={() => {
              props.handleCloseSlider(index);
            }}
            handleOnChangeInternal={(value: any) => {
              props.handleSliderChange(value, index);
            }}
            handleOnChange={(value: any) => {
              props.getSliderValues(value, index);
            }}
          />
        </Paper>
      </div>
    );
  });
  return sliders;
};

/**
 *
 * @param props
 */
const ChartView = (props: ChartViewProps) => {
  const style = { width: "100%", minHeight: "500px" };
  i18next.addResourceBundle("en", "translation", EN_US);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const normalizeAltitudeFromStore = useSelector(
    (state: any) => state.qualityAssurance.normalizeAltitude
  );

  const plotConfig: any = {
    displayModeBar: !props.hideActions,
    displaylogo: false,
    responsive: true,
    staticPlot: false,
  };
  if (props.chartViewType === ChartViewType.polar) {
    plotConfig.staticPlot = true;
  }
  if (props.chartViewType === ChartViewType.scattersWithSlider) {
    let multiChartsData: Plotly.Data[][] = props.multiChartData;
    let charts: JSX.Element[] = [];
    let chips: JSX.Element[] = [];

    let sliders = getSliders(props.valuesOfSliders, props.labels, props);
    multiChartsData.forEach((element, index) => {
      chips.push(
        <div className="chip" key={props.labels[index]}>
          <Chip
            className={props.selectedPlotIndex === index ? "active" : ""}
            label={props.labels[index]}
            onClick={(event) => {
              props.handleRadioChange(index);
            }}
          />
        </div>
      );
      if (props.selectedPlotIndex === index) {
        let plotData: any = [];
        let crossReferenceData = null;

        if (
          props.mode === "preQA" &&
          props.crossReferenceData &&
          props.crossReferenceData.length === 2 &&
          props.crossReferenceData[index] &&
          props.crossReferenceData[index].data
        ) {
          crossReferenceData = props.crossReferenceData[index].data[0];
        }

        if (crossReferenceData) {
          plotData.push(crossReferenceData);
        }
        plotData.push(props.originalMultiChartData[index][0]);

        plotData.push(...element);

        charts.push(
          <div key={props.labels[index] + index}>
            <div className="plots">
              <Paper>
                <Plot
                  data={plotData}
                  layout={props.getLayout(
                    props.theme,
                    ChartViewType.scattersWithSlider,
                    ""
                  )}
                  useResizeHandler={true}
                  style={style}
                  config={plotConfig}
                  className="chart-view"
                />
              </Paper>
            </div>
            <div className="bottom-drawer-content">
              <div className="sliders">{sliders}</div>
              <div className="add-range">
                <Button
                  variant="contained"
                  onClick={() => {
                    props.handleAddSlider();
                  }}
                >
                  <AddIcon /> Measurement Range
                </Button>
              </div>
              {window.location.href.includes("survey_quality_assurance") && (
                <div className="checkbox-text">
                  <Checkbox
                    defaultChecked={true}
                    checked={
                      normalizeAltitudeFromStore === undefined
                        ? true
                        : normalizeAltitudeFromStore &&
                          normalizeAltitudeFromStore === true
                        ? true
                        : false
                    }
                    onChange={(event: any, checked: boolean) => {
                      dispatch({
                        type: "@@qualityAssurance/SET_NORMALIZE_ALTITUDE",
                        normalizeAltitude: checked,
                      });
                    }}
                  />
                  <Typography>
                    {t("msg.survey.quantification.normalize.altitude")}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        );
      }
    });

    return (
      <div className="charts-sliders-view">
        <div className="chips-group">{chips}</div>
        {charts}
      </div>
    );
  }
  return (
    <Plot
      data={props.getData(props.theme, props.chartViewType)}
      layout={props.getLayout(
        props.theme,
        props.chartViewType,
        props.displayTitle
      )}
      useResizeHandler={true}
      style={style}
      config={plotConfig}
      className="chart-view"
    />
  );
};

export default ChartView;
