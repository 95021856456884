// Copyright 2021 SeekOps Inc.
import { CustomTheme } from "../../../../config/theme/theme.interfaces";
import { Layout, Legend } from "plotly.js";

/**
 *
 *
 * @param {string} title
 * @param {theme} theme
 */
export const getCoreLayout = (
  theme: CustomTheme,
  title?: string
): Partial<Layout> => {
  if (theme) {
    return {
      title: {
        text: title,
        font: {
          size: theme.typography.fontSize,
          family: theme.typography.fontFamily,
          color: theme.palette.text.primary,
        },
      },
      height: 500,
      paper_bgcolor: "transparent",
      plot_bgcolor: "transparent",
      font: {
        size: (theme.typography.fontSize * 4) / 5,
        family: theme.typography.fontFamily,
        color: theme.palette.text.primary,
      },
      margin: {
        l: 40,
        r: 40,
        t: 120,
        b: 100,
      },
      yaxis: {
        side: "left",
      },
    };
  } else {
    return {};
  }
};

/**
 * default legend settings
 */
export const getCoreXLegend = (): Partial<Legend> => {
  return {
    orientation: "h",
    xanchor: "center",
    x: 0.5,
    traceorder: "normal",
  };
};
