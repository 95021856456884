// Copyright 2021 SeekOps Inc.

import React, { lazy, Suspense } from "react";

const LazyNotificationCenter = lazy(() => import("./NotificationCenter"));

const NotificationCenter = (
  props: JSX.IntrinsicAttributes & { children?: React.ReactNode }
) => (
  <Suspense fallback={null}>
    <LazyNotificationCenter {...props} />
  </Suspense>
);

export default NotificationCenter;
