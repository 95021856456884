// Copyright 2021 SeekOps Inc.
// third-party
import { ofType } from "redux-observable";
import { map, catchError, takeUntil, switchMap } from "rxjs/operators";
import { ajax } from "rxjs/ajax";

const verifyTokenURL = "https://soda.seekops.com/token/verify/";
const refreshTokenURL = "https://soda.seekops.com/token/refresh/";

const handleTokenVerificationSuccess = (payload: any) => {
  return {
    type: "@@authentication/TOKEN_VERIFY_CURRENT_SUCCESS",
    token: localStorage.getItem("JWT"),
    refreshToken: localStorage.getItem("JWTr"),
    userID: localStorage.getItem("SODA_user"),
    companyID: localStorage.getItem("SODA_company"),
  };
};

export const verifyTokenEpic = (action$: any, state$: any) => {
  const accessToken = localStorage.getItem("JWT");
  if (!accessToken) {
    return [
      {
        type: "@@authentication/SIGN_OUT",
      },
    ];
  }
  return action$.pipe(
    ofType("@@authentication/TOKEN_VERIFY_CURRENT"),
    switchMap((value: any) =>
      ajax({
        url: verifyTokenURL,
        body: { token: accessToken },
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      }).pipe(
        map((payload: any) => handleTokenVerificationSuccess(payload)),
        takeUntil(action$.ofType("TOKEN_VERIFY_CURRENT_CANCEL")),
        catchError((payload) => [
          {
            type: "@@authentication/TOKEN_REFRESH",
            error: true,
            payload,
          },
        ])
      )
    ),
    catchError((payload) => [{
      type: "@@authentication/TOKEN_VERIFY_CURRENT",
      error: true,
    },])
  );
};

const handleTokenRefreshSuccess = (payload: any, refreshToken: string) => {
  return {
    type: "@@authentication/TOKEN_REFRESH_SUCCESS",
    token: payload.response.access,
    refreshToken: refreshToken,
    payload,
  };
};

export const refreshTokenEpic = (action$: any, state$: any) => {
  const accessToken = localStorage.getItem("JWT");
  const refreshToken = localStorage.getItem("JWTr");
  if (!refreshToken) {
    return [
      {
        type: "@@authentication/SIGN_OUT",
      },
    ];
  }
  return action$.pipe(
    ofType("@@authentication/TOKEN_REFRESH"),
    switchMap((value: any) =>
      ajax({
        url: refreshTokenURL,
        body: {
          access: accessToken,
          refresh: refreshToken,
        },
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      }).pipe(
        map((payload: any) => handleTokenRefreshSuccess(payload, refreshToken)),
        takeUntil(action$.ofType("@@authentication/TOKEN_REFRESH_CANCEL")),
        catchError((payload) => [
          {
            type: "@@authentication/SIGN_OUT",
            error: true,
          },
        ])
      )
    ),
    catchError((payload) => [{
      type: "@@authentication/TOKEN_REFRESH",
      error: true,
    },])
  );
};
