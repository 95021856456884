// Copyright 2021 SeekOps Inc.
import { Action } from "@material-table/core";
import { TableActionsItf } from "./TableActions.interfaces";
import {
  IconButton,
  Tooltip,
  MenuItem,
  Menu,
  Button,
  Divider,
  Box,
} from "@mui/material";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import "./TableActions.scss";

const TableActions = (props: TableActionsItf) => {
  const actionAggregationThresholdCount = 3;

  /**
   * gets a count of actions that are not hidden from the user in order to
   * know if actions need to be aggregated or not
   * @param tableActions
   */
  const getViewableActionCount = (tableActions: Action<any>[]): number => {
    let count = 0;
    if (tableActions && tableActions.length) {
      count = tableActions.filter((tableAction) => {
        return tableAction.hidden !== true;
      }).length;
    }
    return count;
  };
  /**
   *
   * @param tableActionsToAggregate
   */
  const getAggregatedActions = (
    tableActions: Action<any>[],
    dividedActions: Action<any>[]
  ): JSX.Element[] => {
    let actionButtonsView: any[] = [];
    let primaryActionButton: any;
    let moreActionsButton: any;
    let primaryAction: Action<any> = tableActions[0];
    let icon: any = primaryAction.icon;
    primaryActionButton = (
      <span className="table-action-button" key={`${Date.now()}0`}>
        <Tooltip
          aria-label={primaryAction.tooltip}
          title={primaryAction.tooltip + ""}
          arrow
        >
          <IconButton
            aria-label={primaryAction.tooltip}
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              primaryAction.onClick(event, props.rowData);
            }}
          >
            {icon}
          </IconButton>
        </Tooltip>
      </span>
    );
    actionButtonsView.push(primaryActionButton);
    moreActionsButton = (
      <span className="table-action-button" key={`${Date.now()}1`}>
        <Tooltip aria-label="More Actions" title="More Actions" arrow>
          <>
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <>
                  <IconButton
                    aria-label="More Actions"
                    {...bindTrigger(popupState)}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    {getActionsMenu(tableActions, dividedActions)}
                  </Menu>
                </>
              )}
            </PopupState>
          </>
        </Tooltip>
      </span>
    );
    actionButtonsView.push(moreActionsButton);
    return actionButtonsView;
  };

  /**
   *
   * @param tableActions
   */
  const getActionsMenu = (
    tableActions: Action<any>[],
    dividedActions: Action<any>[]
  ): JSX.Element[] => {
    let menu: any[] = [];
    tableActions.forEach((action: Action<any>, index) => {
      // don't aggregate with the first action ()
      if (index > 0) {
        let icon: any = action.icon;
        let actionView = (
          <MenuItem
            disabled={action.disabled}
            className="table-action-menu-item"
            key={"action-item-" + index}
          >
            <Button
              className="table-action-menu-button"
              // startIcon=
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                action.onClick(event, props.rowData);
              }}
            >
              <span color="primary">{icon}</span>&nbsp;{action.tooltip}
            </Button>
          </MenuItem>
        );
        if (!action.hidden) {
          menu.push(actionView);
        }
      }
    });
    if (dividedActions.length > 0) {
      menu.push(<Divider key={`divider-${Date.now()}`} />);

      dividedActions.forEach((action, index) => {
        let icon: any = action.icon;

        let actionView = (
          <MenuItem
            disabled={action.disabled}
            className="table-action-menu-item"
            key={`action-item-${Date.now()}-${index}`}
          >
            <Button
              className="table-action-menu-button"
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                action.onClick(event, props.rowData);
              }}
            >
              <span color="primary">{icon}</span>&nbsp;{action.tooltip}
            </Button>
          </MenuItem>
        );
        if (!action.hidden) {
          menu.push(actionView);
        }
      });
    }
    return menu;
  };

  /**
   *
   */
  const getTableActions = (): JSX.Element => {
    let tableActions = props?.actions;
    let dividedActions = props?.dividedActions;
    let dividedActionsCount = 0;
    if (props.dividedActions) {
      dividedActionsCount = getViewableActionCount(dividedActions);
    }

    let actionButtonsView: any[] = [];
    if (
      getViewableActionCount(tableActions) + dividedActionsCount >
      actionAggregationThresholdCount
    ) {
      actionButtonsView = getAggregatedActions(tableActions, dividedActions);
    } else {
      let allActions = [...tableActions];
      if (props.dividedActions) {
        allActions = allActions.concat(props.dividedActions);
      }

      allActions.forEach((action: Action<any>, index) => {
        let isDisabled: boolean = action.disabled || props?.rowData?.disabled;
        let icon: any = action.icon;
        let actionView = (
          <Tooltip
            aria-label={action.tooltip}
            title={action.tooltip + ""}
            key={"tooltip-" + index}
            arrow
          >
            <span>
              <IconButton
                className={
                  "table-action-buttons" +
                  (isDisabled ? " MuiButton-disabled" : "")
                }
                aria-label={action.tooltip}
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  action.onClick(event, props.rowData);
                }}
                // check if action is disabled or if row says actions should be disabled
                disabled={isDisabled === true}
              >
                {icon}
              </IconButton>
            </span>
          </Tooltip>
        );
        if (!action.hidden) {
          actionButtonsView.push(actionView);
        }
      });
    }

    return (
      <Box className="table-actions" sx={{ position: "relative" }}>
        {actionButtonsView}
      </Box>
    );
  };

  // return the actions markup
  return getTableActions();
};

export default TableActions;
