// Copyright 2021 SeekOps Inc.
// third-party
import { Reducer } from "redux";

// first-party
import { surveysState, surveysActionTypes } from "./surveys.types";

// Type-safe initialState!
export const initialSurveysState: surveysState = {
  isMoreActionsOpen: false,
  isInfoOpen: false,
  infoContent: null,
  infoMap: {},
  surveysToMerge: [],
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings
// on the reducer side, everything will remain type-safe.
const surveysReducer: Reducer<surveysState> = (
  state = initialSurveysState,
  action
) => {
  switch (action.type) {
    case surveysActionTypes.SET_INFO_CONTENT: {
      return { ...state, infoContent: action.infoContent };
    }
    case surveysActionTypes.SET_INFO_OPEN: {
      return { ...state, isInfoOpen: action.isInfoOpen };
    }
    case surveysActionTypes.SET_INFO_MAP: {
      return { ...state, infoMap: action.infoMap };
    }
    case surveysActionTypes.SET_SURVEYS_TO_MERGE: {
      return { ...state, surveysToMerge: action.surveysToMerge };
    }
    default:
      return state;
  }
};

// Instead of using default export, we use named exports. That way we can group
// these exports inside the `index.js` folder.
export default surveysReducer;
