// Copyright 2021 SeekOps Inc.
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {};

export interface Language {
  label: string;
  languageCode: string;
  name: string;
}

export const supportedLanguages = [
  // English USA
  {
    label: "English",
    languageCode: "en-US",
    name: "English",
  },
  // Arabic Saudi Arabia
  {
    label: "عربى",
    languageCode: "ar-SA",
    name: "Arabic",
  },
  // Chinese China
  {
    label: "简体中文",
    languageCode: "zh-CN",
    name: "Chinese",
  },
  // Spanish Mexico
  {
    label: "Español",
    languageCode: "es-MX",
    name: "Spanish",
  },
  // Portuguese Brazil
  {
    label: "Português",
    languageCode: "pt-BR",
    name: "Portuguese",
  },
  // Russian Russia
  {
    label: "русский",
    languageCode: "ru-RU",
    name: "Russian",
  },
  // French France
  {
    label: "français",
    languageCode: "fr-FR",
    name: "French",
  },
  // Italian Italy
  {
    label: "Italiano",
    languageCode: "it-IT",
    name: "Italian",
  },
];

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    resources,
    lng: "en-US", // get the language/locale from the store
    fallbackLng: "en-US", // fall back to English USA
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
