// Copyright 2021 SeekOps Inc.

import { useMsal } from "@azure/msal-react";
import { Box, Button } from "@mui/material";
import { loginRequest } from "../../authConfig";

/**
 * Renders a button which, when selected, will open a popup for login
 */
const SSOLoginButton = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType: any) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.error("MSAL LOGIN ERROR | ", e);
      });
    }
  };
  return (
    <Box sx={{ justifyContent: "center", textAlign: "center" }}>
      <Button
        aria-label="SSO sign in"
        role="button"
        variant="outlined"
        className="ml-auto"
        onClick={() => handleLogin("popup")}
      >
        Sign in using Microsoft
      </Button>
    </Box>
  );
};

export default SSOLoginButton;
