// Copyright 2021 SeekOps Inc.
// react
import { Component, createElement } from "react";

// third-party
import clonedeep from "lodash/cloneDeep";

// first-party
import axios from "../../../../AJAX";
import { Layout, Legend, PlotData } from "plotly.js";
import { getCoreLayout, getCoreXLegend } from "./Chart.config";
import {
  ChartProps,
  ChartState,
  ChartOmniViewProps,
} from "./ChartType.interfaces";
import { CustomTheme } from "../../../../config/theme/theme.interfaces";
import { ChartViewType } from "../Chart.enums";
import ChartOmniView from "./Chart-omni.view";

/**
 *
 */
class ChartBar extends Component<ChartProps, ChartState> {
  _isMounted = false;
  _coreLayout: Partial<Layout> = {};
  _coreXLegend: Partial<Legend> = {};

  constructor(props: ChartProps) {
    super(props);

    this.state = {
      data: props.data || [],
      theme: props.theme,
      displayTitle: props.displayTitle || "",
      labels: props.labels || [],
    };
  }

  /**
   *
   */
  componentDidMount = () => {
    this._isMounted = true;
    // init core config
    this._coreLayout = getCoreLayout(this.props.theme, this.props.displayTitle);
    this._coreXLegend = getCoreXLegend();
    // apply data
    this.applyData(this.props.data, this.props.endpoint);
  };

  /**
   *
   */
  componentDidUpdate = (prevProps: ChartProps) => {
    // always update core layout for theme
    this._coreLayout = getCoreLayout(this.props.theme, this.props.displayTitle);

    if (
      this.props.theme !== prevProps.theme &&
      this.props.data &&
      this.props.data !== prevProps.data
    ) {
      this.setState({
        ...this.state,
        theme: this.props.theme,
        data: this.props.data,
      });
    } else if (this.props.theme !== prevProps.theme) {
      // update theme
      this.setState({ ...this.state, theme: this.props.theme });
    } else if (this.props.data && this.props.data !== prevProps.data) {
      // update data
      this.setState({ ...this.state, data: this.props.data });
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  getDemoData = (): Partial<PlotData>[] => {
    // backup data to display
    let barChartData: Partial<PlotData>[] = [
      // 1 set of data
      {
        x: [
          "Liam",
          "Sophie",
          "Eric",
          "Jacob",
          "Mia",
          "William",
          "Olivia",
          "Stuart",
          "Christopher",
        ],
        y: [8.0, 8.0, 19.0, 12.0, 12.0, 13.0, 20.0, 22.0, 23.0],
        type: "bar",
        text: [
          "4.17 below the mean",
          "4.17 below the mean",
          "0.17 below the mean",
          "0.17 below the mean",
          "0.83 above the mean",
          "7.83 above the mean",
        ],
        marker: {
          color: this.state.theme.chartPalette,
        },
        width: 0.8,
      },
    ];
    return barChartData;
  };

  /**
   *
   *
   */
  applyData = (
    data: Partial<PlotData>[] | null | undefined,
    endpoint: string | null | undefined
  ): void => {
    let barChartData: Partial<PlotData>[] = [];
    if (data && data.length) {
      barChartData = data;
      this.setState({ ...this.state, data: barChartData });
    } else if (endpoint) {
      // check if there is an endpoint
      // make axios call and set data in state
      axios
        .get(endpoint)
        .then((response: any) => {
          if (this._isMounted) {
            // only set state if mounted
            this.setState((state, props) => ({
              ...this.state,
              data: response.data,
            }));
          }
        })
        .catch((error) => {
          console.error("ERROR | ", error);
        });
    } else {
      if (this._isMounted) {
        this.setState({ ...this.state, data: this.getDemoData() });
      }
    }
  };

  /**
   *
   */
  getLayout = (): Partial<Layout> => {
    let layout: Partial<Layout> = {
      ...this._coreLayout,
      showlegend: false,
      xaxis: {
        tickangle: -45,
      },
      yaxis: {
        zeroline: false,
        gridwidth: 2,
      },
      bargap: 1,
    };
    return layout;
  };

  /**
   * return whatever state data exists but with markers set to current
   * theme's colors
   */
  getData = (): Partial<PlotData>[] => {
    // ensure marker theme is up to date
    let dataSets: Partial<PlotData>[] = clonedeep(this.state.data);

    return this.applyColorsToData(dataSets, this.state.theme);
  };

  /**
   * iterates through data and apply current theme's color palette to markers
   *
   * @returns data collection with theme colors
   */
  applyColorsToData = (
    dataSets: Partial<PlotData>[],
    theme: CustomTheme
  ): Partial<PlotData>[] => {
    dataSets.forEach((dataSet: Partial<PlotData>) => {
      if (dataSet && dataSet.marker) {
        dataSet.marker.color = theme.chartPalette;
      }
    });
    return dataSets;
  };

  render = () => {
    let renderData = this.getData();

    if (renderData && renderData.length) {
      const chartOmniViewProps: ChartOmniViewProps = {
        testAttribute: "component-chart-bar",
        data: renderData,
        layout: this.getLayout(),
        labels: this.props.labels!,
        theme: this.state.theme,
        chartViewType: ChartViewType.bar,
      };
      return createElement(ChartOmniView, { ...chartOmniViewProps });
    } else {
      return createElement(
        "div",
        { className: "chart-no-data" },
        "No chart data available."
      );
    }
  };
}

export default ChartBar;
