// Copyright 2021 SeekOps Inc.

// react
import { ChangeEvent, useState } from "react";
import { InputAdornment, IconButton, TextField, Button } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AccountCircle from "@mui/icons-material/AccountCircle";

// first-party
import "./Authentication.view.scss";
import { useTranslation } from "react-i18next";
import useAuthentication from "./useAuthentication";

/**
 * Presents the visual aspect of authenticating a user
 *
 * @param props - values and logic provided by a parent component
 */
export const AuthenticationLegacy = () => {
  const { authenticate, hasError } = useAuthentication();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { t } = useTranslation();

  /**
   *
   */
  const handleSubmit = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    const credentials = { username: username, password: password };
    authenticate(credentials);
  };

  // JSX templates
  const signIn = (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        autoFocus
        required
        id="username"
        label={t("msg.login.username")}
        value={username}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setUsername(e.target.value);
        }}
        className="input-login-content user-name"
        fullWidth
        inputProps={{ "aria-label": "sign-in-username" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <AccountCircle />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{ shrink: username !== "" }}
        margin="normal"
        variant="standard"
      />

      <TextField
        required
        id="password"
        aria-label="password"
        label={t("msg.login.password")}
        value={password}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setPassword(e.target.value);
        }}
        type={showPassword ? "text" : "password"}
        className="input-login-content"
        fullWidth
        inputProps={{ "aria-label": "sign-in-password" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{ shrink: password !== "" }}
        margin="normal"
        variant="standard"
      />
      <br />
      <br />
      <Button variant="contained" size="large" type="submit">
        Submit
      </Button>
      <br />
      {hasError && (
        <>
          <p>Credentials were incorrect. Please try again.</p>
          <br />
        </>
      )}
    </form>
  );

  return signIn;
};
