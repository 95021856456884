// Copyright 2021 SeekOps Inc.
export enum NavigationTabs {
  SurveyPostQA = "surveys_post_quality_assurance",
  Surveys = "surveys",
  Overview = "overview",
  Import = "import",
  Sites = "sites",
  Fluxplane = "fluxplane",
  Projects = "projects",
  SurveyQuality = "survey_quality_assurance",
  Report = "report",
}
