// Copyright 2021 SeekOps Inc.
import React from "react";

import "./AppTitleLogo.scss";

const AppTitleLogo = () => {
  return (
    <div className="app-title-animation" dir="ltr">
      <div className="app-title" dir="ltr" aria-label="SODA">
        SODA
      </div>
      <div className="app-title-smoke">
        &#x200E;
        <span dir="ltr">S</span>
        <span dir="ltr">O</span>
        <span dir="ltr">D</span>
        <span dir="ltr">A</span>
      </div>
    </div>
  );
};

export default AppTitleLogo;
