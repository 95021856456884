// Copyright 2021 SeekOps Inc.
// first-party
import { CustomThemeOptions } from "./theme.interfaces";

/**
 * This is where global paddings, margins, font sizes, and colors that should be
 * applied to EVERY other theme are to be set.
 *
 * specific themes should only have color overrides
 */
const themeDefaultOverrides: CustomThemeOptions = {
  chartPalette: [],
  direction: "ltr",
  palette: {},
  components: {
    MuiList: {
      styleOverrides: {
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        gutters: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "auto",
          margin: "0 1rem",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        img: {
          height: "auto",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "inherit",
          fill: "inherit",
        },
        containedPrimary: {
          fontWeight: "bold",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: "inherit",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          alignItems: "center",
          display: "flex",
          flexWrap: "nowrap",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontWeight: 300,
          color: "red",
        },
        head: {
          fontSize: "1.5rem",
          fontWeight: 300,
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: "inherit",
          fill: "inherit",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        caption: {
          fontWeight: 300,
          fontSize: "1.5rem",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          fontWeight: 300,
          fontSize: "1.5rem",
        },
        selectIcon: {
          color: "inherit",
          fill: "inherit",
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: "blue",
          "&.failed": {
            color: "red",
          },
          "&.completed": {
            color: "green",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          marginTop: "3rem",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          justifyContent: "space-between",
          display: "flex",
        },
      },
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontSize: 18,
    fontFamily: ["Roboto", "Arial", "sans-serif"].join(","),
  },
};

export default themeDefaultOverrides;
