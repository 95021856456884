// Copyright 2021 SeekOps Inc.
import { getBaseURL } from "./global";
import axios, { AxiosInstance } from "axios";

const getCurrentAccessToken = (): string => {
  let access_token: string | null = getJWT();
  return access_token + ""; // ensure string is returned
};

// store pending requests while a token refresh is occuring
const requestArray: any[] = [];

/**
 *
 * @return headers object to include with a request
 */
const getHeaders = (): object => {
  let headers: any = {};
  let token = getJWT();

  if (token) {
    // add JWT if it exists
    headers.Authorization = "Bearer " + token;
  }
  return headers;
};

/**
 * set web token in local storage/store
 *
 * @param JWT - web token to be set
 */
export const setJWT = async (JWT: string) => {
  if (localStorage) {
    localStorage.setItem("JWT", JWT);
  }
};

/**
 * Returns the current access token
 *
 * @returns the current access token
 */
export const getJWT = (): string | null => {
  let JWT = "";
  try {
    if (localStorage) {
      JWT = localStorage.getItem("JWT") || "";
    }
  } catch (error: any) {
    console.error("getJWT | ", error);
  }
  return JWT;
};

/*
 * set refresh token in local storage/store
 *
 * @param JWTr - web token to be set
 */
export const setJWTr = (JWTr: string) => {
  if (localStorage) {
    localStorage.setItem("JWTr", JWTr);
  }
};

/**
 * Returns the current refresh token
 *
 * @returns the current refresh token
 *
 */

export const getJWTr = (): string | null => {
  let JWTr = "";
  try {
    if (localStorage) {
      JWTr = localStorage.getItem("JWTr") || "";
    }
  } catch (error: any) {
    console.error("getJWTr | ", error);
  }
  return JWTr;
};

/**
 * Creates an instance of Axios for api use
 *
 * @param baseURL
 * @param headers
 * @return AxiosInstance
 */
export const getAxiosInstance = (
  baseURL: string,
  headers: any
): AxiosInstance => {
  let axiosInstance = axios.create({
    baseURL: baseURL,
    headers: headers,
  });

  // Interceptor for Response
  axiosInstance.interceptors.response.use(
    (response) => {
      // update request queue
      if (requestArray.length !== 0) {
        requestArray.forEach((request, i) => {
          if (response.config.url === request.url) {
            requestArray.splice(i, 1);
          }
        });
      }
      return response;
    },

    async (error: any) => {
      // check if auth error
      if (error.response && error.response.status === 401) {
        // only add error response requests to collection
        const originalRequest = error.config;
        // if url doesn't already exist in collection, then add it
        let index = requestArray.findIndex((currentRequest) => {
          return error.config.url === currentRequest.url;
        });
        if (index === -1) {
          requestArray.push(originalRequest);
        }
        // check if it has been retried
        if (!originalRequest._retry) {
          originalRequest._retry = true;
          // get tokens for refresh call
          const refresh_token: string | null = getJWTr();
          let access_token = getCurrentAccessToken();

          try {
            // make call to refresh
            const response = await axiosInstance.post("/token/refresh/", {
              access: access_token,
              refresh: refresh_token,
            });
            const new_access_token = response.data.access;
            if (new_access_token) {
              if (response.status === 200) {
                setJWT(new_access_token);
                const authHeader = `Bearer ${new_access_token}`;
                // update the headers in axios instance for subsequent requests
                axiosInstance.defaults.headers.common["Authorization"] =
                  authHeader;
                if (requestArray.length !== 0) {
                  requestArray.forEach((request) => {
                    try {
                      // update the headers in request from array
                      request.headers.Authorization = authHeader;
                      axiosInstance(request);
                    } catch (e) {
                      console.error(e);
                    }
                  });
                }
                return axiosInstance(originalRequest);
              }
            }
          } catch (error: any) {
            console.error("TOKEN REFRESH ERROR: ", error);
          }
        }
      }
      return Promise.reject(error);
    }
  );

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    (config) => {
      // set the config headers Authorization token to what is in storage
      const authToken = getCurrentAccessToken();
      if (authToken) {
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${authToken}`;
      }

      if (config.url) {
        // check if there are any params
        let tokens = config.url.split("?");
        if (tokens.length === 1) {
          // when no params, end with slash
          if (
            config.url.substr(-1) !== "/" &&
            !config.url.endsWith(".xml") &&
            !config.url.endsWith(".png") &&
            !config.url.endsWith(".jpg") &&
            !config.url.endsWith(".JPG")
          )
            config.url += "/";
        } else {
          // when params, make sure there is no slash
          config.url = tokens[0].replace(/\/$/, "") + "/?" + tokens[1];
        }
      }
      // Do something before request is sent
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

/**
 *
 */
const axiosInstance: AxiosInstance = getAxiosInstance(
  getBaseURL(),
  getHeaders()
);

// Instantiate the interceptor (you can chain it as it returns the axios instance)
// createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

/**
 *
 */
const destroyTokens = () => {
  localStorage.removeItem("JWT");
  localStorage.removeItem("JWTr");
};

// /**
//  * checks if the given auth token is valid; if not, the token attempts to
//  * refresh the token with the given refresh token; if that fails, an empty
//  * string is returned
//  *
//  * @param authToken
//  * @param refreshToken
//  * @returns string containing valid auth token; empty string if valid token
//  * could not be returned
//  */
// export const getValidAuthToken = async (
//   authToken?: string,
//   refreshToken?: string
// ) => {
//   const currentAuthToken = authToken || getJWT();
//   const currentRefreshToken = refreshToken || getJWTr();
//   if (currentAuthToken && currentRefreshToken) {
//     const response = await fetch(getBaseURL() + "/token/verify/", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${currentAuthToken}`,
//       },
//       body: JSON.stringify({
//         token: currentAuthToken,
//       }),
//     });
//     let access = "";
//     let refreshedAuthToken = await response.json();
//     if (refreshedAuthToken && refreshedAuthToken.access) {
//       access = refreshedAuthToken.access;
//     }
//     // store the new access token
//     setJWT(access);
//   }
// };

// /**
//  * Fetch-based async token refreshing that returns the token as a string;
//  * returns empty string if resfresh fails
//  */
// export const getRefreshedAuthToken = async (
//   authToken?: string,
//   refreshToken?: string
// ) => {
//   const currentAuthToken = authToken || getJWT();
//   const currentRefreshToken = refreshToken || getJWTr();
//   let access = "";
//   if (currentAuthToken && currentRefreshToken) {
//     const response = await fetch(getBaseURL() + "/token/refresh/", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${currentAuthToken}`,
//       },
//       body: JSON.stringify({
//         access: currentAuthToken,
//         refresh: currentRefreshToken,
//       }),
//     });
//     let refreshedAuthToken = await response.json();
//     if (refreshedAuthToken && refreshedAuthToken.access) {
//       access = refreshedAuthToken.access;
//     }
//     // store the new access token
//     setJWT(access);
//   }
//   return access;
// };

/**
 *
 * @param axiosInstance - the instance of axios for which to refresh token
 * @param JWT
 * @param JWTr
 */
export const refreshToken = (
  axiosInstance: AxiosInstance,
  JWT?: string,
  JWTr?: string
): Promise<boolean> => {
  let storedToken = getJWTr();
  let storedRefreshToken = getJWTr();

  // in case no arguments are provided
  if (!JWT && storedToken) {
    JWT = storedToken;
  }

  if (!JWTr && storedRefreshToken) {
    JWTr = storedRefreshToken;
  }

  //let wasSuccessfullyRefreshed = false;
  return axiosInstance
    .post("/token/refresh/", { access: JWT, refresh: JWTr })
    .then(({ data }) => {
      if (data) {
        // successfully refreshed so store the new token
        setJWT(data.access);
        // update axios instance header
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.access}`;
        //wasSuccessfullyRefreshed = true;
      }
      return data.access;
    })
    .catch((err) => {
      // refresh failed so remove expired tokens
      destroyTokens();
      return err;
    });
};

/**
 *
 * @param axiosInstance - the instance of axios for which to refresh token
 * @param JWT
 * @param JWTr
 */
export const awaitRefreshToken = async (
  axiosInstance: AxiosInstance,
  JWT?: string,
  JWTr?: string
) => {
  let storedToken = getJWT();
  let storedRefreshToken = getJWTr();

  // in case no arguments are provided
  if (!JWT && storedToken) {
    JWT = storedToken;
  }

  if (!JWTr && storedRefreshToken) {
    JWTr = storedRefreshToken;
  }

  //let wasSuccessfullyRefreshed = false;
  let response = await axiosInstance.post("/token/refresh/", {
    access: JWT,
    refresh: JWTr,
  });

  if (response.status !== 200) {
    destroyTokens();
    throw new Error(`HTTP error! status: ${response.status}`);
  } else {
  }
  const data = await response.data;

  if (data) {
    // successfully refreshed so store the new token
    setJWT(data.access);
    // update axios instance header
    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${data.access}`;
    //wasSuccessfullyRefreshed = true;
    return data.access;
  }
  return "";
};

export default axiosInstance;
