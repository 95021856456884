// Copyright 2021 SeekOps Inc.

import { useState, MouseEvent, useEffect, Fragment } from "react";
import {
  ToggleButtonGroup,
  ToggleButton,
  Box,
  Tooltip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Divider,
} from "@mui/material";

import { RootState } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import { ProcessingTypes } from "../Import/ProcessingTypes/ProcessingTypes.enum";

export enum FlightType {
  none, //0
  unknown, //1
  fluxplane, //2
  offshore, //3
  perimeterOnshore, //4
}

const SurveyFlightType = (props: {
  fileType: ProcessingTypes;
  onTypeChange: (value: any) => void;
}) => {
  // default is fluxplane
  const [flightType, setFlightType] = useState<FlightType>(
    FlightType.fluxplane
  );

  const [fileType, setFileType] = useState<ProcessingTypes>(
    ProcessingTypes.File
  );

  const { onTypeChange } = props;

  const persistedFlightType = useSelector(
    (state: RootState) => state.qualityAssurance.flightType
  );

  useEffect(() => {
    setFlightType(persistedFlightType);
    // reset selected file type when  when flight type is changed
    setFileType(ProcessingTypes.File);
  }, [persistedFlightType]);

  useEffect(() => {
    setFileType(props.fileType);
  }, [props.fileType]);

  const dispatch = useDispatch();

  const handleChange = (
    event: MouseEvent<HTMLElement>,
    newFlightType: FlightType
  ) => {
    dispatch({
      type: "@@qualityAssurance/SET_FLIGHT_TYPE",
      flightType: newFlightType,
    });
  };

  return (
    <Fragment>
      <Box
        className="survey-flight-type-root"
        sx={{ display: "flex", alignItems: "center", padding: "3rem 0 0 0" }}
      >
        <Grid container sx={{ alignItems: "center" }}>
          <Grid item sm={12} lg={6} sx={{ padding: "0 2rem 2rem 2rem" }}>
            <ToggleButtonGroup
              fullWidth
              color="primary"
              value={flightType}
              exclusive
              onChange={handleChange}
              aria-label="Flight Type"
            >
              <ToggleButton value={FlightType.fluxplane}>
                Fluxplane/Default
                <Tooltip
                  sx={{ marginLeft: "1rem", marginRight: "1rem" }}
                  arrow
                  title={
                    "An onshore flight in which pilots fly multiple “flux plane” flight patterns on a location. These flight patterns create a vertical cross section of the site. Historically, this is the default flight type."
                  }
                >
                  <InfoIcon />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value={FlightType.offshore}>
                Offshore
                <Tooltip
                  sx={{ marginLeft: "1rem", marginRight: "1rem" }}
                  arrow
                  title={
                    "A flight that takes place offshore, in which pilots navigate around the perimeter of offshore equipment to collect measurements."
                  }
                >
                  <InfoIcon />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value={FlightType.perimeterOnshore}>
                Perimeter Onshore
                <Tooltip
                  sx={{ marginLeft: "1rem", marginRight: "1rem" }}
                  arrow
                  title={
                    "An onshore flight in which pilots fly around the entire perimeter of select equipment groups on a given site."
                  }
                >
                  <InfoIcon />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>

          <Grid item sm={12} lg={6} sx={{ padding: "0 2rem 2rem 2rem" }}>
            <Box className="survey-flight-type-file-type-root">
              <FormControl fullWidth variant="standard">
                <InputLabel id="select-file-type-label">
                  Select type of file
                </InputLabel>

                <Select
                  fullWidth
                  autoWidth
                  value={fileType}
                  onChange={(event: any) => {
                    onTypeChange(event.target.value);
                  }}
                  labelId="select-project-label"
                  id="select-project"
                >
                  <MenuItem
                    key={ProcessingTypes.File}
                    value={ProcessingTypes.File}
                  >
                    mdt/dat
                  </MenuItem>

                  <MenuItem
                    key={ProcessingTypes.bin}
                    value={ProcessingTypes.bin}
                    disabled={
                      flightType === FlightType.fluxplane ||
                      flightType === FlightType.perimeterOnshore
                    }
                  >
                    bin/csv
                  </MenuItem>

                  <MenuItem
                    key={ProcessingTypes.csv}
                    value={ProcessingTypes.csv}
                    disabled={
                      flightType === FlightType.fluxplane ||
                      flightType === FlightType.offshore
                    }
                  >
                    csv
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />
    </Fragment>
  );
};

export default SurveyFlightType;
