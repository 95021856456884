// Copyright 2021 SeekOps Inc.
import React from "react";
import {
  Autocomplete,
  Slider,
  Tooltip,
  IconButton,
  FormControl,
  Checkbox,
  TextField,
  Box,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  SliderAssociation,
  SliderInfoViewProps,
} from "./SliderInfo.interfaces";
import InfoIcon from "@mui/icons-material/Info";
import CancelIcon from "@mui/icons-material/Cancel";

import "./SliderInfo.view.scss";

/**
 *
 * @param props
 */
export const SliderInfoView = (props: SliderInfoViewProps) => {
  const sliderStyling = {
    // Defaults to Cathmas Blue
    "& .MuiSlider-root": {
      color: props.color ? props.color : "#154b7e",
    },
  };
  const mainClass = "slider-container ";

  /**
   *
   * @param event
   */
  const handleChangeMultiple = (
    event: React.SyntheticEvent<unknown>,
    value: any[]
  ) => {
    // update the state of the container so that sliders have access to associations
    props.setAssociations(value);
  };

  /**
   *
   * @param potentialAssociations
   */
  const getAssociationSelection = (
    potentialAssociations: SliderAssociation[]
  ) => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    return (
      <FormControl className="association-select" fullWidth={true}>
        <Autocomplete
          className="association-selection"
          multiple
          id="checkboxes-tags-demo"
          options={potentialAssociations}
          disableCloseOnSelect
          getOptionLabel={(option) => {
            return option.name + " : " + option.id;
          }}
          onChange={(e, values) => {
            handleChangeMultiple(e, values);
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name + " : " + option.id}
            </li>
          )}
          style={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={props.sliderAssociationsMessageLabel}
              label={props.sliderAssociationsMessageLabel}
            />
          )}
        />
      </FormControl>
    );
  };

  return (
    <Box className={mainClass} sx={sliderStyling}>
      <div className="label">
        {props.label}
        &nbsp;
        <div className="buttons">
          {props.description && (
            <Tooltip title={props.description}>
              <InfoIcon className="info-icon" />
            </Tooltip>
          )}
          <div className="close-icon" hidden={!props.closable}>
            <Tooltip
              aria-label="Remove This Range"
              title="Remove This Range"
              arrow
            >
              <IconButton
                onClick={() => {
                  props.handleOnCloseClick!();
                }}
              >
                <CancelIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>

      <Slider
        valueLabelDisplay={props.isLabelOn ? props.isLabelOn : "off"}
        value={props.value}
        step={props.step ? props.step : 1}
        min={props.min}
        max={props.max}
        onChange={(e, value) => {
          props.handleOnChangeInternal!(value);
        }}
        onChangeCommitted={(e, value) => {
          if (props.handleOnChange) {
            props.handleOnChange(value);
          }
        }}
      />

      {/* display associations to intervals if any exist */}
      {props.potentialAssociations && props.potentialAssociations.length > 0 && (
        <div className="association-selection">
          {getAssociationSelection(props.potentialAssociations)}
          <br />
        </div>
      )}
    </Box>
  );
};
