// Copyright 2021 SeekOps Inc.
// react
import { Component, createElement } from "react";

// third-party
import clonedeep from "lodash/cloneDeep";

// first-party
import axios from "../../../../AJAX";
import { Layout, Legend, PlotData } from "plotly.js";
import { getCoreLayout, getCoreXLegend } from "./Chart.config";
import {
  ChartProps,
  ChartState,
  ChartOmniViewProps,
} from "./ChartType.interfaces";
import ChartTypeView from "./Chart-omni.view";
import { CustomTheme } from "../../../../config/theme/theme.interfaces";
import { ChartViewType } from "../Chart.enums";

/**
 *
 */
class ChartPolar extends Component<ChartProps, ChartState> {
  _isMounted = false;
  _coreLayout: Partial<Layout> = {};
  _coreXLegend: Partial<Legend> = {};

  constructor(props: ChartProps) {
    super(props);

    this.state = {
      data: [],
      theme: props.theme,
      displayTitle: props.displayTitle || "",
      labels: props.labels || [],
      heading: props.heading || 0,
      hideActions: props.hideActions,
    };
  }

  /**
   *
   */
  componentDidMount = () => {
    this._isMounted = true;
    // init core config
    this._coreLayout = getCoreLayout(this.props.theme, this.props.displayTitle);
    this._coreXLegend = getCoreXLegend();
    // apply data
    this.applyData(this.props.data, this.props.endpoint);
  };

  /**
   *
   */
  componentDidUpdate = (prevProps: ChartProps) => {
    // always update core layout for theme
    this._coreLayout = getCoreLayout(this.props.theme, this.props.displayTitle);
    if (
      this.props.theme !== prevProps.theme &&
      this.props.data &&
      this.props.data !== prevProps.data
    ) {
      this.setState({
        ...this.state,
        theme: this.props.theme,
        data: this.props.data,
      });
    } else if (this.props.theme !== prevProps.theme) {
      // update theme
      this.setState({ ...this.state, theme: this.props.theme });
    } else if (this.props.data && this.props.data !== prevProps.data) {
      // update data
      this.setState({ ...this.state, data: this.props.data });
    } else if (this.props.heading && this.props.heading !== prevProps.heading) {
      // update heading
      this.setState({ ...this.state, heading: this.props.heading });
    }
  };

  /**
   *
   */
  componentWillUnmount = () => {
    this._isMounted = false;
  };

  /**
   *
   */
  getDemoData = (): any => {
    // backup data to display
    let polarChartData: any = [
      {
        r: [77.5, 72.5, 70.0, 45.0, 22.5, 42.5, 40.0, 62.5],
        theta: ["North", "N-E", "East", "S-E", "South", "S-W", "West", "N-W"],
        name: "11-14 m/s",
        marker: { color: "rgb(106,81,163)" },
        type: "barpolar",
      },
      {
        r: [57.5, 50.0, 45.0, 35.0, 20.0, 22.5, 37.5, 55.0],
        theta: ["North", "N-E", "East", "S-E", "South", "S-W", "West", "N-W"],
        name: "8-11 m/s",
        marker: { color: "rgb(158,154,200)" },
        type: "barpolar",
      },
      {
        r: [40.0, 30.0, 30.0, 35.0, 7.5, 7.5, 32.5, 40.0],
        theta: ["North", "N-E", "East", "S-E", "South", "S-W", "West", "N-W"],
        name: "5-8 m/s",
        marker: { color: "rgb(203,201,226)" },
        type: "barpolar",
      },
      {
        r: [20.0, 7.5, 15.0, 22.5, 2.5, 2.5, 12.5, 22.5],
        theta: ["North", "N-E", "East", "S-E", "South", "S-W", "West", "N-W"],
        name: "< 5 m/s",
        marker: { color: "rgb(242,240,247)" },
        type: "barpolar",
      },
    ];
    return polarChartData;
  };

  /**
   *
   *
   */
  applyData = (
    data: Partial<PlotData>[] | null | undefined,
    endpoint: string | null | undefined
  ): void => {
    let polarChartData: Partial<PlotData>[] = [];
    if (data && data.length) {
      polarChartData = data;
      this.setState({ ...this.state, data: polarChartData });
    } else if (endpoint) {
      // check if there is an endpoint
      // make axios call and set data in state
      axios
        .get(endpoint)
        .then((response: any) => {
          // check if there was a response in case the Endpoint isn't allowed
          if (response && this._isMounted) {
            // only set state if mounted
            this.setState((state, props) => ({
              ...this.state,
              data: response.data,
            }));
          }
        })
        .catch((error) => {
          console.error("ERROR | ", error);
        });
    } else {
      if (this._isMounted) {
        this.setState({ ...this.state, data: this.getDemoData() });
      }
    }
  };

  /**
   *
   * @returns {any} layout because the polar types are still not correct
   */
  getLayout = (): any => {
    let rotation = 0;
    if (this.props.heading !== undefined) {
      rotation = this.props.heading;
    }
    let layout: any = {
      ...this._coreLayout,
      showlegend: !this.props.hideLegend,
      legend: { ...this._coreXLegend },
      polar: {
        bgcolor: "transparent",
        angularaxis: { direction: "clockwise", rotation: rotation },
        radialaxis: {
          showticklabels: false,
        },
      },
    };

    if (this.props.hideActions) {
      layout.height = "400px";
    }
    return layout;
  };

  /**
   * return whatever state data exists but with markers set to current
   * theme's colors
   */
  getData = (): Partial<PlotData>[] => {
    //ensure marker theme is up to date
    let dataSets: Partial<PlotData>[] = clonedeep(this.state.data);
    return this.applyColorsToData(dataSets, this.state.theme);
  };

  /**
   * iterates through data and apply current theme's color palette to markers
   *
   * @returns data collection with theme colors
   */
  applyColorsToData = (
    dataSets: Partial<PlotData>[],
    theme: CustomTheme
  ): Partial<PlotData>[] => {
    dataSets.forEach((dataSet: Partial<PlotData>, index: number) => {
      if (dataSet && dataSet.marker) {
        dataSet.marker.color = theme.chartPalette[index];
      }
    });
    return dataSets;
  };

  render = () => {
    let renderData = this.getData();

    if (renderData && renderData.length) {
      const chartOmniViewProps: ChartOmniViewProps = {
        testAttribute: "component-chart-polar",
        data: renderData,
        layout: this.getLayout(),
        theme: this.state.theme,
        labels: this.props.labels!,
        chartViewType: ChartViewType.polar,
        hideActions: this.state.hideActions,
      };
      return createElement(ChartTypeView, { ...chartOmniViewProps });
    } else {
      return createElement(
        "div",
        { className: "chart-no-data" },
        "No chart data available."
      );
    }
  };
}

export default ChartPolar;
