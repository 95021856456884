// Copyright 2021 SeekOps Inc.
// third-party
import { Reducer } from "redux";

// first-party
import { fluxPlaneState, fluxplaneActionTypes } from "./fluxplane.types";

// Type-safe initialState!
export const initialState: fluxPlaneState = {
  sliderValuesAssociations: {},
  rank_values: [],
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings
// on the reducer side, everything will remain type-safe.
const fluxplaneReducer: Reducer<fluxPlaneState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case fluxplaneActionTypes.SET_CONFIG: {
      let newState: fluxPlaneState = {
        ...state,
        //API Returns a key-value pair thus labels need to be access via [key]
        [Object.keys(action)[1]]: action[Object.keys(action)[1]],
      };
      return newState;
    }
    case fluxplaneActionTypes.SET_SLIDER_VALUES: {
      let newState: fluxPlaneState = {
        ...state,
        //API Returns a key-value pair thus labels need to be access via [key]
        slider_values: action[Object.keys(action)[1]],
      };
      return newState;
    }
    case fluxplaneActionTypes.SET_RANK_VALUES: {
      let newState: fluxPlaneState = {
        ...state,
        //API Returns a key-value pair thus labels need to be access via [key]
        rank_values: action[Object.keys(action)[1]],
      };
      return newState;
    }
    case fluxplaneActionTypes.INIT_SLIDER_ASSOCIATIONS: {
      let newState: fluxPlaneState = {
        ...state,
        sliderAssociationsEndpoint: action.sliderAssociationsEndpoint,
        sliderAssociationsMessageLabel: action.sliderAssociationsMessageLabel,
      };
      return newState;
    }
    case fluxplaneActionTypes.SET_SLIDER_VALUES_ASSOCIATIONS: {
      // get current associations
      let updatedSliderValuesAssociations = {
        ...state.sliderValuesAssociations,
      };
      // set the given association
      updatedSliderValuesAssociations[action.interval] =
        action.sliderAssociations;
      // apply updated association to state
      let newState: fluxPlaneState = {
        ...state,
        sliderValuesAssociations: updatedSliderValuesAssociations,
      };
      return newState;
    }
    case fluxplaneActionTypes.CLEAR_SLIDER_VALUES_ASSOCIATIONS: {
      // clear the slider values associations
      let newState: fluxPlaneState = {
        ...state,
        sliderValuesAssociations: initialState.sliderValuesAssociations,
      };
      return newState;
    }
    case fluxplaneActionTypes.SET_SETTINGS_PROFILE: {
      let newState: fluxPlaneState = {
        ...state,
        profile: action.profile,
      };
      return newState;
    }
    case fluxplaneActionTypes.RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

// Instead of using default export, we use named exports. That way we can group
// these exports inside the `index.js` folder.
export default fluxplaneReducer;
