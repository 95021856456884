// Copyright 2021 SeekOps Inc.
// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define whichever naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the

import { NotificationType } from "../../components/Notification/NotificationTypes.enum";
import { FluxplaneInterval } from "../../components/SurveyQualityAssurance/ReviewFluxplane/ReviewFluxplane.interfaces";
import { FlightType } from "../../components/SurveyQualityAssurance/SurveyFlightType";
import { QualityAssuranceStep } from "../../components/SurveyQualityAssurance/SurveyQualityAssurance.interfaces";

// convention of Redux's `@@INIT` action.
export enum QualityAssuranceActionTypes {
  SET_CURRENT_STEP = "@@qualityAssurance/SET_CURRENT_STEP",
  SET_SURVEY_ID_FOR_REVIEW = "@@qualityAssurance/SET_SURVEY_ID_FOR_REVIEW",
  SET_FLUXPLANE_INTERVALS = "@@qualityAssurance/SET_FLUXPLANE_INTERVALS",
  RESET = "@@qualityAssurance/RESET",
  SET_NOTES = "@@qualityAssurance/SET_NOTES",
  SET_SURVEY_DAT_FOR_REVIEW = "@@qualityAssurance/SET_SURVEY_DAT_FOR_REVIEW",
  SET_SURVEY_FOR_REVIEW = "@@qualityAssurance/SET_SURVEY_FOR_REVIEW",
  SET_SURVEY_FOR_REVIEW_STATUS = "@@qualityAssurance/SET_SURVEY_FOR_REVIEW_STATUS",
  SET_SHOW_SELECTION = "@@qualityAssurance/SET_SHOW_SELECTION",
  SET_IS_PROCESSING_FINISHED = "@@qualityAssurance/SET_IS_PROCESSING_FINISHED",
  SET_VALIDITY_CHECKLIST_COMPLETE = "@@qualityAssurance/SET_VALIDITY_CHECKLIST_COMPLETE",
  SET_UPLOAD_PHOTO = "@@qualityAssurance/SET_UPLOAD_PHOTO",
  SET_IS_UPLOAD_PHOTO_DISABLED = "@@qualityAssurance/SET_IS_UPLOAD_PHOTO_DISABLED",
  SET_FLIGHT_TYPE = "@@qualityAssurance/SET_FLIGHT_TYPE",
  SET_NORMALIZE_ALTITUDE = "@@qualityAssurance/SET_NORMALIZE_ALTITUDE",
}

export interface QualityAssuranceState {
  currentStep: QualityAssuranceStep;
  surveyIDForReview: number;
  surveySitesForReview: string;
  surveyTimeForReview: string;
  surveyDATForReview: string;
  surveyForReviewStatus: NotificationType;
  fluxplaneIntervals: FluxplaneInterval[] | null;
  validityChecklistComplete: boolean;
  notes: string;
  showSelection: boolean;
  isProcessingFinished: boolean;
  uploadPhoto: boolean;
  isUploadPhotoDisabled: boolean;
  flightType: FlightType;
  normalizeAltitude: boolean;
}

export default QualityAssuranceActionTypes;
