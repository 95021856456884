// Copyright 2021 SeekOps Inc.
// third-party
import { Reducer } from "redux";

// first-party
import { tasksState, tasksActionTypes } from "./tasks.types";
import { TaskType } from "./TaskTypes.enum";

// Type-safe initialState!
export const initialTaskState: tasksState = {
  [TaskType.File]: {},
  [TaskType.Photo]: {},
  [TaskType.Report]: {},
  fileQuantity: 0,
  inProgress: [],
  completed: [],
  error: [],
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings
// on the reducer side, everything will remain type-safe.
const taskReducer: Reducer<tasksState> = (state = initialTaskState, action) => {
  switch (action.type) {
    case tasksActionTypes.ADD_TASK: {
      let taskType = state[action.taskType as TaskType];

      let newState: tasksState = {
        ...state,
        //API Returns a key-value pair thus labels need to be access via [key]
        [action.taskType as TaskType]: {
          ...taskType,
          [action.task.id]: action.task,
        },
      };
      return newState;
    }

    case tasksActionTypes.REMOVE_TASK: {
      //Given that properties are key signature pairs,
      //properties can be removed using the JS 'delete' operator
      delete state[action.taskType as TaskType][action.id];
      let newState: tasksState = {
        ...state,
      };

      return newState;
    }

    case tasksActionTypes.REMOVE_ALL_TASKS: {
      let newState: tasksState = {
        ...state,
        [action.taskType]: initialTaskState[action.taskType as TaskType],
      };

      // when removing all file tasks, reset the file quanity
      if (action.taskType === TaskType.File) {
        newState = {
          ...state,
          [action.taskType]: initialTaskState[action.taskType as TaskType],
          fileQuantity: initialTaskState.fileQuantity,
        };
      }

      return newState;
    }

    case tasksActionTypes.SET_FILE_QUANTITY: {
      let newState: tasksState = {
        ...state,
        fileQuantity: action.fileQuantity,
      };

      return newState;
    }

    case tasksActionTypes.SET_COMPLETED: {
      let newState: tasksState = {
        ...state,
        completed: action.completed,
      };

      return newState;
    }
    case tasksActionTypes.SET_IN_PROGRESS: {
      let newState: tasksState = {
        ...state,
        inProgress: action.inProgress,
      };

      return newState;
    }
    case tasksActionTypes.SET_ERROR: {
      let newState: tasksState = {
        ...state,
        error: action.error,
      };

      return newState;
    }
    case tasksActionTypes.RESET: {
      let newState: tasksState = {
        [TaskType.File]: {},
        [TaskType.Photo]: {},
        [TaskType.Report]: {},
        fileQuantity: 0,
        inProgress: [],
        completed: [],
        error: [],
      };
      return newState;
    }

    default:
      return state;
  }
};

// Instead of using default export, we use named exports. That way we can group
// these exports inside the `index.js` folder.
export default taskReducer;
