// Copyright 2021 SeekOps Inc.
import {
  BrowserCacheLocation,
  Configuration,
  PopupRequest,
} from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: "19e56e6b-d4ba-4e0d-948b-bef98f203a0e",
    authority:
      "https://login.microsoftonline.com/dd38e5a0-643a-4ddf-b6cc-fdde7fa8bfcf",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ["User.Read"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
