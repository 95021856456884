// Copyright 2021 SeekOps Inc.
// third-party
import { Reducer } from "redux";

// first-party
import { geoMapState, geoMapActionTypes } from "./geoMap.types";

// Type-safe initialState!
export const initialGeoMapState: geoMapState = {
  activeHeatMaps: [],
  emissionIds: [],
  sketchPayload: null,
  labelSketchPayload: null,
};

/**
 * initial state to use when loaded
 */
const getInitialGeoMapState = (): geoMapState => {
  let state: geoMapState = initialGeoMapState;
  // any logic to retrieve data from storage or tweak the state goes here
  return state;
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings
// on the reducer side, everything will remain type-safe.
const geoMapReducer: Reducer<geoMapState> = (
  state = getInitialGeoMapState(),
  action
) => {
  switch (action.type) {
    case geoMapActionTypes.SET_ACTIVE_HEAT_MAPS: {
      return { ...state, activeHeatMaps: action.activeHeatMaps };
    }

    case geoMapActionTypes.SET_EMISSION_IDS: {
      return { ...state, emissionIds: action.emissionIds };
    }

    case geoMapActionTypes.SET_SKETCH_PAYLOAD: {
      return { ...state, sketchPayload: action.sketchPayload };
    }

    case geoMapActionTypes.SET_LABEL_SKETCH_PAYLOAD: {
      return { ...state, labelSketchPayload: action.labelSketchPayload };
    }

    default:
      return state;
  }
};

// Instead of using default export, we use named exports. That way we can group
// these exports inside the `index.js` folder.
export default geoMapReducer;
