// Copyright 2021 SeekOps Inc.
// third-party
import { merge } from "lodash/fp";

// first-party
import getColorPalette from "./theme.colors";
import { ColorPaletteName, ColorName } from "./theme.enums";
import { CustomThemeOptions } from "./theme.interfaces";
import themeDefaultOverrides from "./theme.default-overrides";

/**
 * array of colors to be used by charts (in this order) [from pantone]
 */
export const chartsDarkPalette = [
  "rgba(0, 199, 86, 1)", // Andean Toucan
  "rgba(252, 249, 81, 1)", // Lemon Tonic
  "rgba(255, 79, 88, 1)", // Fiery Coral
  "rgba(0, 99, 178, 1)", // Electric Blue Lemonade
  "rgba(255, 111, 29, 1)", // Orange Clown Fish
  "rgba(255, 62, 165, 1)", // Knockout Pink
  "rgba(105, 255, 71, 1)", // Green Gecko
  "rgba(105, 68, 142, 1)", // Electric Purple
  "rgba(77, 198, 226, 1)", // Bluefish
  "rgba(255, 186, 82, 1)", // Orange Pop
];

const ThemeConfigDark: CustomThemeOptions = {
  chartPalette: chartsDarkPalette,
  direction: "ltr",
  palette: {
    mode: "dark",
    primary: getColorPalette(ColorPaletteName.lightgray),
    secondary: getColorPalette(ColorPaletteName.oxbloodred),
    error: getColorPalette(ColorPaletteName.primaryred),
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: ColorName.seekopsWhite,
          fill: ColorName.seekopsWhite,
          "&.Mui-selected": {
            color: ColorName.seekopsInfoBlueLight,
            fill: ColorName.seekopsInfoBlueLight,
            svg: {
              fill: ColorName.seekopsInfoBlueLight,
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: ColorName.seekopsBlack,
        },
        indicator: {
          backgroundColor: ColorName.seekopsBlue,
        },
        scrollButtons: {
          color: ColorName.seekopsBlue,
          fill: ColorName.seekopsBlue,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          color: ColorName.seekopsWhite,
          "&.Mui-disabled": {
            svg: {
              fill: "rgba(255, 255, 255, 0.12)"
            }
          }
        },
        contained: {
          backgroundColor: ColorName.seekopsGreen,
          color: ColorName.seekopsWhite,
          fill: ColorName.seekopsWhite,
        },
        containedPrimary: {
          backgroundColor: ColorName.seekopsInfoBlue,
          color: ColorName.seekopsWhite,
          fill: ColorName.seekopsWhite,
        },
        containedSecondary: {
          backgroundColor: ColorName.seekopsRed,
          color: ColorName.seekopsWhite,
          fill: ColorName.seekopsWhite,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: ColorName.seekopsWhite,
          fill: ColorName.seekopsWhite,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: ColorName.seekopsWhite,
          fill: ColorName.seekopsWhite,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        colorPrimary: {
          color: ColorName.seekopsBlack,
        },
        thumb: {
          color: ColorName.seekopsBlue2,
        },
        track: {
          backgroundColor: ColorName.seekopsBlue2,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        primary: {
          color: ColorName.seekopsWhite,
          backgroundColor: ColorName.seekopsGreen,
        },
        root: {
          backgroundColor: ColorName.seekopsInfoBlue,
          color: ColorName.seekopsWhite,
          fill: ColorName.seekopsWhite,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: ColorName.seekopsWhite,
          fill: ColorName.seekopsWhite,
          backgroundColor: "#303030 !important",
          "&.table-actions": {
            color: ColorName.seekopsWhite,
            fill: ColorName.seekopsWhite,
          },
        },
        head: {
          color: ColorName.seekopsInfoBlueLight,
          fill: ColorName.seekopsInfoBlueLight,
          backgroundColor: "#303030 !important",
        },
        body: {
          color: ColorName.seekopsWhite + "  !important",
          fill: ColorName.seekopsWhite + "  !important",
          backgroundColor: "#303030 !important",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        caption: {
          color: ColorName.seekopsBlue,
        },
        root: {
          color: ColorName.seekopsWhite,
          fill: ColorName.seekopsWhite,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          color: ColorName.seekopsInfoBlueLight,
        },
        selectIcon: {
          fill: ColorName.seekopsInfoBlueLight,
          color: ColorName.seekopsInfoBlueLight,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: ColorName.seekopsWhite,
          fill: ColorName.seekopsWhite,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: ColorName.seekopsWhite,
        },
        colorSecondary: {
          fill: ColorName.seekopsWhite,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          fill: ColorName.seekopsWhite,
          "&.Mui-active": {
            fill: "rgba(0, 99, 178, 1)",
          },
          "&.Mui-completed": {
            fill: "rgba(0, 199, 86, 1)",
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          color: ColorName.seekopsWhite,
        },
      },
    },
  },
};

export default merge(themeDefaultOverrides, ThemeConfigDark);
