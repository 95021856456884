// Copyright 2021 SeekOps Inc.
// set up and export the Redux store

// third-party
import { combineReducers, compose } from "redux";
import { createStore, applyMiddleware } from "redux";
import { createEpicMiddleware, combineEpics } from "redux-observable";

// first-party
import authorizationReducer from "./authorization";
import themeReducer, { ThemeState } from "./theme";
import surveyDialogReducer from "./surveyDialog";
import { surveyDialogState } from "./surveyDialog/surveyDialog.types";
import languageSelectorStoreReducer from "./languageSelectorStore/languageSelectorStore.reducer";
import authenticationReducer from "./authentication/authentication.reducers";
import windroseReducer from "./windrose/windrose.reducers";
import surveysReducer from "./surveys/surveys.reducers";
import {
  verifyTokenEpic,
  refreshTokenEpic,
} from "./authentication/authentication.utils";
import { AuthenticationState } from "./authentication/authentication.types";
import { WindroseState } from "./windrose/windrose.types";
import { surveysState } from "./surveys/surveys.types";
import { languageSelectorStoreState } from "./languageSelectorStore";
import { sideBarState } from "./sideBar/sideBar.types";
import sideBarReducer from "./sideBar/sideBar.reducers";
import { fluxPlaneState } from "./fluxplane/fluxplane.types";
import fluxplaneReducer from "./fluxplane";
import taskReducer from "./tasks";
import { tasksState } from "./tasks/tasks.types";
import { AuthorizationState } from "./authorization/authorization.types";
import { preferencesState } from "./preferences/preferences.types";
import preferencesReducer from "./preferences";
import { QualityAssuranceState } from "./qualityAssurance/qualityAssurance.types";
import qualityAssuranceReducer from "./qualityAssurance";
import { ErrorDialogState } from "./errorDialog/errorDialog.types";
import errorDialogReducer from "./errorDialog";
import { geoMapState } from "./geoMap/geoMap.types";
import geoMapReducer from "./geoMap";
import notificationReducer from "./notification";
import { notificationState } from "./notification/notification.types";

// The top-level state object.
//
// `connected-react-router` already injects the router state typings for us,
// so we can ignore them here.
export interface ApplicationState {
  theme: ThemeState;
  authentication: AuthenticationState;
  authorization: AuthorizationState;
  notification: notificationState;
  languageSelector: languageSelectorStoreState;
  windrose: WindroseState;
  sideBar: sideBarState;
  surveyDialog: surveyDialogState;
  fluxPlaneConfig: fluxPlaneState;
  tasks: tasksState;
  preferences: preferencesState;
  surveys: surveysState;
  qualityAssurance: QualityAssuranceState;
  errorDialog: ErrorDialogState;
  geoMap: geoMapState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
// export const rootReducer = combineReducers<ApplicationState>({
//   layout: LayoutReducer
// });

export const rootReducer = combineReducers({
  authorization: authorizationReducer,
  authentication: authenticationReducer,
  notification: notificationReducer,
  theme: themeReducer,
  language: languageSelectorStoreReducer,
  windrose: windroseReducer,
  sideBar: sideBarReducer,
  surveyDialog: surveyDialogReducer,
  fluxPlaneConfig: fluxplaneReducer,
  tasks: taskReducer,
  preferences: preferencesReducer,
  surveys: surveysReducer,
  qualityAssurance: qualityAssuranceReducer,
  errorDialog: errorDialogReducer,
  geoMap: geoMapReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const rootEpic = combineEpics(verifyTokenEpic, refreshTokenEpic);
// set this to true only when debugging tests along with redux state
const DEBUG_MODE = false;

export type AppState = ReturnType<typeof rootReducer>;

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: Function;
  }
}

// const composeEnhancers =
//   (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const epicMiddleware = createEpicMiddleware();

const initialState = {};

/**
 * Logs all actions and states after they are dispatched.
 */
const logger = (store: any) => (next: any) => (action: any) => {
  console.group(action.type);
  console.info("dispatching", action);
  let result = next(action);
  console.log("next state", store.getState());
  console.groupEnd();
  return result;
};

const configureStore = () => {
  // create the composing function for our middlewares
  const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  // configure middlewares
  let middlewares = DEBUG_MODE ? [epicMiddleware, logger] : [epicMiddleware];
  // compose enhancers
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  // create store
  const store = createStore(rootReducer, initialState, enhancer);
  epicMiddleware.run(rootEpic);
  return store;
};

const store = configureStore();

export default store;
