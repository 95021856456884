// Copyright 2021 SeekOps Inc.

// react
import { useCallback, useEffect, useState, Fragment } from "react";

// third party
import {
  Button,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Grid,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

// first party
import "./SurveyDetails.scss";
import Chart from "../UI/Chart";
import { ChartViewType } from "../UI/Chart/Chart.enums";
import axiosInstance from "../../AJAX";
import EN_US from "./ResourceBundles/en_US.json";
import { Can } from "../../Can";
import { FluxplaneRunLayerStatus } from "../GeoMap/GeoMap.interfaces";
import {
  getFluxplaneRuns,
  sortFluxplaneRuns,
} from "../Survey/SurveyFluxplaneRuns";
import { connect } from "react-redux";
import SlideTransition from "../UI/Utils";
import { geoMapActionTypes } from "../../store/geoMap";
import FluxPlaneContainer from "../FluxPlane";
import AsyncGeoMap from "../GeoMap";

i18next.addResourceBundle("en-US", "translation", EN_US);

const SurveyDetails = (props: {
  surveyId: number | null;
  concentrationData?: number[];
  altitudeData?: number[];
  timestampData?: string[];
  startTime?: string;
  datFile?: string;
  status: number;
  fluxplaneRunStatuses?: FluxplaneRunLayerStatus[];
  setSurveyFluxplaneRunData: (
    fluxplaneRunIds: number[],
    fluxplaneRunStatuses: FluxplaneRunLayerStatus[],
    activeFluxplaneId: number
  ) => void;
  setSelectedSurveyId: React.Dispatch<React.SetStateAction<number | null>>;
  setEmissionIds: (emissionIds: number[]) => void;
  setTimeOfLastReview: (timeinmillis: number) => void;
}) => {
  const { t } = useTranslation();

  // initial states
  const [isOpen, setIsOpen] = useState(false);
  const initialConcentrationPlotData: any[] = [];
  const initialAltitudePlotData: any[] = [];
  const initialSpikesPlotData: any[] = [];
  const initialSurveyData: any = {};
  const initialSurveyNotes: any = [];
  const initialIsFluxplaneDialogOpen: boolean = false;

  // state handlers
  const [altitudePlotData, setAltitudePlotData] = useState(
    initialAltitudePlotData
  );
  const [concentrationPlotData, setConcentrationPlotData] = useState(
    initialConcentrationPlotData
  );
  const [spikesPlotData, setSpikesPlotData] = useState(initialSpikesPlotData);
  const [surveyData, setSurveyData] = useState(initialSurveyData);
  const [surveyNotes, setSurveyNotes] = useState(initialSurveyNotes);
  const [isFluxplaneDialogOpen, setIsFluxplaneDialogOpen] = useState(
    initialIsFluxplaneDialogOpen
  );

  /**
   *
   */
  async function getEmissionSpikes(emissionId: number) {
    let response = await axiosInstance.get(
      `/api/emissions/${emissionId}/emission-measurements/`
    );

    if (response.data) {
      const concentrations = response.data.map((feature: any) => {
        return feature.concentration * 1000000;
      });

      const timestamps = response.data.map((feature: any) => {
        return feature.timestamp;
      });

      return {
        y: concentrations,
        x: timestamps,
        mode: "markers",
        name: "#" + emissionId,
        marker: {
          size: 8,
          line: {
            color: "rgb(50, 50, 50)",
            width: 1,
          },
        },
      };
    } else {
      return {};
    }
  }

  /**
   *
   */
  const getSurveyData = useCallback((surveyId: number) => {
    axiosInstance.get(`/api/surveys/${surveyId}/`).then((response: any) => {
      if (response.data) {
        setSurveyData(response.data);
      }
    });
  }, []);

  /**
   * updates the state by updating the value of the surveyNotes with the values
   * retrieved from the endpoint call
   *
   * @param surveyId
   */
  const getNotes = useCallback((surveyId: number) => {
    if (surveyId >= 0) {
      let notesURL = `/api/surveys/${surveyId}/surveyannotations`;
      axiosInstance.get(notesURL).then((response: any) => {
        if (response.data && response.data.length) {
          setSurveyNotes(response.data);
        }
      });
    }
  }, []);

  /**
   *
   */
  const getSpikes = useCallback(
    (surveyId: number) => {
      /**
       *
       * @param emissionIds
       */
      const getEmissionsSpikes = (emissionIds: number[]) => {
        let spikes: any[] = [];
        emissionIds.forEach((emissionId) => {
          spikes.push(getEmissionSpikes(emissionId));
        });
        Promise.all(spikes).then((values) => {
          setSpikesPlotData(values);
        });
      };

      axiosInstance
        .get(`/api/surveys/${surveyId}/emissions/`)
        .then((emissionsResponse) => {
          if (emissionsResponse.data) {
            // iterate over emissions objects
            let spikesURLCollection = emissionsResponse.data.map(
              (emission: any) => {
                return emission.id;
              }
            );
            // store the IDs in redux
            if (spikesURLCollection && spikesURLCollection.length) {
              props.setEmissionIds(spikesURLCollection);
            }
            getEmissionsSpikes(spikesURLCollection);
          }
        })
        .catch((error: any) => {
          console.error("Survey approval failed", error);
        });
    },
    [props]
  );

  /**
   *
   * @param surveyId
   * @param fprId
   */
  const approve = (surveyId: number, fprId: number) => {
    axiosInstance
      .post(`/api/surveys/${surveyId}/fluxplaneruns/${fprId}/approve/`)
      .then((response: any) => {
        setIsOpen(false);
        props.setTimeOfLastReview(Date.now());
      })
      .catch((error: any) => {
        console.error("Survey approval failed", error);
      });
  };

  /**
   *
   * @param surveyId
   * @param fprId
   */
  const reject = (surveyId: number, fprId: number) => {
    axiosInstance
      .post(`/api/surveys/${surveyId}/fluxplaneruns/${fprId}/reject/`)
      .then((response: any) => {
        setIsOpen(false);
        props.setTimeOfLastReview(Date.now());
      })
      .catch((error: any) => {
        console.error("Survey rejection failed", error);
      });
  };

  useEffect(() => {
    const setSelectedSurveyId = props.setSelectedSurveyId;
    const surveyId = props.surveyId;
    if (surveyId !== null) {
      setIsOpen(true);
      getSurveyData(surveyId);
      getNotes(surveyId);
      getSpikes(surveyId);
    } else {
      // clear survey data
      setSelectedSurveyId(null);
      setSurveyData([]);
      setSpikesPlotData([]);
      setSurveyNotes([]);
      setAltitudePlotData([]);
      setConcentrationPlotData([]);
      // close dialog
      setIsOpen(false);
    }
  }, [
    props.surveyId,
    getSurveyData,
    getNotes,
    getSpikes,
    props.setSelectedSurveyId,
  ]);

  useEffect(() => {
    if (props.concentrationData && props.timestampData) {
      setConcentrationPlotData([
        {
          y: props.concentrationData,
          x: props.timestampData,
          mode: "markers",
          name: "Concentration",
          marker: { size: 4 },
        },
      ]);
    }
  }, [props.concentrationData, props.timestampData]);

  /**
   *
   */
  const getFluxplaneRunStatuses = useCallback(
    (surveyId: number) => {
      let surveyFluxplaneRuns: FluxplaneRunLayerStatus[] = [];
      // deconstruct from props
      const setSurveyFluxplaneRunData = props.setSurveyFluxplaneRunData;
      if (surveyId > 0) {
        getFluxplaneRuns(surveyId).then((response: any) => {
          surveyFluxplaneRuns = sortFluxplaneRuns(response.data);
          // set the data in the store
          setSurveyFluxplaneRunData(
            [],
            surveyFluxplaneRuns,
            surveyData.fluxplanerun_qa_needed
          );
        });
      }
    },
    [props.setSurveyFluxplaneRunData, surveyData.fluxplanerun_qa_needed]
  );

  useEffect(() => {
    const surveyId = props.surveyId;
    if (surveyId !== null) {
      getFluxplaneRunStatuses(surveyId);
    }
  }, [props.surveyId, getFluxplaneRunStatuses]);

  useEffect(() => {
    if (props.altitudeData && props.timestampData) {
      setAltitudePlotData([
        {
          y: props.altitudeData,
          x: props.timestampData,
          mode: "markers",
          name: "Altitude",
          marker: { size: 4 },
        },
      ]);
    }
  }, [props.altitudeData, props.timestampData]);

  const openFluxplaneDialog = () => {
    setIsFluxplaneDialogOpen(true);
  };

  const closeFluxplaneDialog = () => {
    setIsFluxplaneDialogOpen(false);
  };

  return (
    <Dialog
      className="survey-so-dialog survey-so-dialog"
      aria-label="survey-post-qa-details"
      fullScreen
      open={isOpen}
      TransitionComponent={SlideTransition}
      onClose={(event, reason) => {
        if (reason === "escapeKeyDown") {
          setIsOpen(false);
        }
      }}
    >
      <AppBar position="static" color="default">
        <Toolbar>
          <Typography variant="h6" color="primary">
            Post QA | {props.datFile} | {props.startTime} | {props.surveyId}
          </Typography>
          <IconButton
            edge="end"
            color="primary"
            onClick={() => {
              setIsOpen(false);
              props.setSelectedSurveyId(null);
            }}
            aria-label="close full-screen dialog"
            className="dialog-button-close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className="survey-details-so-wrapper">
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <Paper className="paper">
              {concentrationPlotData && concentrationPlotData.length > 0 && (
                <Chart
                  displayTitle={"Concentration"}
                  chartViewType={ChartViewType.scatter}
                  data={[...concentrationPlotData, ...spikesPlotData]}
                />
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Paper className="paper">
              {altitudePlotData && (
                <Chart
                  displayTitle={"Altitude"}
                  chartViewType={ChartViewType.scatter}
                  data={altitudePlotData}
                />
              )}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            {surveyData.fluxplanerun_qa_needed &&
              props.fluxplaneRunStatuses &&
              props.fluxplaneRunStatuses.length > 0 && (
                <AsyncGeoMap
                  surveyID={props.surveyId}
                  features={{
                    basestation: true,
                    sites: true,
                    flightpath: true,
                    conductor: true,
                    sitestiles: true,
                    images: true,
                    emissions: true,
                    equipmentGroups: true,
                    windrose: true,
                  }}
                  fluxplaneRunStatuses={props.fluxplaneRunStatuses}
                />
              )}
          </Grid>

          <Grid item xs={12}>
            <Paper className="paper">
              <Typography variant="h5">{t("msg.post-qa.notes")}</Typography>
              {/* display the notes */}
              {surveyNotes &&
                surveyNotes.length > 0 &&
                surveyNotes?.map((item: any, i: number) => (
                  <Fragment>
                    <Typography variant="caption" component="p">
                      {item.time_created}
                    </Typography>
                    <pre className="notes" key={i}>
                      {item.notes}
                    </pre>
                  </Fragment>
                ))}
              {/* when there are no notes available */}
              {surveyNotes && surveyNotes.length === 0 && (
                <Typography>{t("msg.post-qa.no-notes")}</Typography>
              )}
            </Paper>
          </Grid>
        </Grid>

        <div className="post-qa-buttons">
          <Button
            disabled={props.status !== 2}
            variant="contained"
            startIcon={<ThumbDownIcon />}
            onClick={() => {
              reject(surveyData.id, surveyData.fluxplanerun_qa_needed);
            }}
          >
            {t("msg.post-qa.reject")}
          </Button>

          <Can do="fpRun" on="fluxplane">
            <Button
              type="button"
              aria-label="Fluxplane"
              onClick={() => {
                openFluxplaneDialog();
              }}
            >
              {t("msg.post-qa.fluxplane")}
            </Button>
          </Can>

          <Button
            disabled={props.status !== 2}
            variant="outlined"
            startIcon={<ThumbUpIcon />}
            onClick={() => {
              approve(surveyData.id, surveyData.fluxplanerun_qa_needed);
            }}
          >
            {t("msg.post-qa.pass")}
          </Button>
        </div>
      </div>

      {isFluxplaneDialogOpen && (
        <Dialog
          className="survey-so-dialog survey-so-dialog"
          fullScreen
          open={isOpen}
          TransitionComponent={SlideTransition}
          onClose={(event, reason) => {
            if (reason === "escapeKeyDown") {
              closeFluxplaneDialog();
            }
          }}
        >
          <AppBar position="static" color="default">
            <Toolbar className="fluxplane-dialog-toolbar">
              <IconButton
                edge="end"
                color="primary"
                onClick={() => {
                  closeFluxplaneDialog();
                }}
                aria-label="close full-screen dialog"
                className="dialog-button-close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <FluxPlaneContainer surveyId={props.surveyId}></FluxPlaneContainer>
        </Dialog>
      )}
    </Dialog>
  );
};

// slice of state to pass to container through props
const mapStateToProps = (state: any) => {
  return {
    fluxplaneRunStatuses: state.surveyDialog.fluxplaneRunStatuses,
  };
};

// actions that should be dispatched
const mapDispatchToProps = (dispatch: any) => {
  return {
    setSurveyFluxplaneRunData: (
      fluxplaneRunIds: number[],
      fluxplaneRunStatuses: FluxplaneRunLayerStatus[],
      activeFluxplaneId: number
    ) => {
      return dispatch({
        type: "@@survey/SET_SURVEY_FLUXPLANE_RUN_DATA",
        fluxplaneRunIds: fluxplaneRunIds,
        fluxplaneRunStatuses: fluxplaneRunStatuses,
        activeFluxplaneId: activeFluxplaneId,
      });
    },
    setEmissionIds: (emissionIds: number[]) => {
      return dispatch({
        type: geoMapActionTypes.SET_EMISSION_IDS,
        emissionIds,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDetails);
