// Copyright 2021 SeekOps Inc.
// react
import { Component, createElement } from "react";

// third-party
import clonedeep from "lodash/cloneDeep";
import i18next from "i18next";
import { Layout, Legend, PlotData, PlotMarker } from "plotly.js";

// first-party
import axios from "../../../../AJAX";

import { getCoreLayout, getCoreXLegend } from "./Chart.config";
import {
  ChartProps,
  ChartState,
  ChartOmniViewProps,
} from "./ChartType.interfaces";
import ChartOmniView from "./Chart-omni.view";
import { CustomTheme } from "../../../../config/theme/theme.interfaces";
import { ChartViewType } from "../Chart.enums";
import EN_US from "../ResourceBundles/en_US.json";
import { withTranslation } from "react-i18next";

/**
 *
 */
class ChartDoughnut extends Component<ChartProps, ChartState> {
  _isMounted = false;
  _coreLayout: Partial<Layout> = {};
  _coreXLegend: Partial<Legend> = {};

  constructor(props: ChartProps) {
    super(props);

    i18next.addResourceBundle("en", "translation", EN_US);

    this.state = {
      data: this.getTransformedInitalData(props.data),
      theme: props.theme,
      displayTitle: props.displayTitle || "",
      labels: props.labels || [],
      total: "",
    };
  }

  /**
   *
   */
  componentDidMount = () => {
    this._isMounted = true;
    // init core config
    this._coreLayout = getCoreLayout(this.props.theme, this.props.displayTitle);
    this._coreXLegend = getCoreXLegend();
    // apply data
    this.applyData(this.props.data, this.props.endpoint);
  };

  /**
   *
   */
  componentWillUnmount = () => {
    this._isMounted = false;
  };

  /**
   *
   */
  componentDidUpdate = (prevProps: ChartProps) => {
    // always update core layout for theme
    this._coreLayout = getCoreLayout(this.props.theme, this.props.displayTitle);

    if (
      this.props.theme !== prevProps.theme &&
      this.props.data &&
      this.props.data !== prevProps.data
    ) {
      this.setState({
        ...this.state,
        theme: this.props.theme,
        data: this.getTransformedInitalData(this.props.data),
      });
    } else if (this.props.theme !== prevProps.theme) {
      // update theme
      this.setState({ ...this.state, theme: this.props.theme });
    } else if (this.props.data && this.props.data !== prevProps.data) {
      // update data
      this.setState({
        ...this.state,
        data: this.getTransformedInitalData(this.props.data),
      });
    }
  };

  /**
   *
   */
  transformInitialData = (initialData: any): void => {
    let data: Partial<PlotData>[] = this.getTransformedInitalData(initialData);
    // let total: string = "";
    // if (initialData.hasOwnProperty("total")) {
    //   total = initialData.total;
    // }

    if (this._isMounted) {
      // only set state if mounted
      this.setState((state, props) => ({
        ...this.state,
        data: data,
      }));
    }
  };

  /**
   *
   */
  getTransformedInitalData = (initialData: any): Partial<PlotData>[] => {
    const baseData: Partial<PlotData> = {
      hoverinfo: "text",
      textposition: "inside",
      hole: 0.3,
      type: "pie",
    };

    let marker = { colors: [] } as Partial<PlotMarker>;
    baseData.marker = marker;
    let doughnutData: Partial<PlotData>[] = [];
    if (initialData) {
      if (initialData.labels && initialData.data && initialData.total) {
        doughnutData = [
          {
            ...baseData,
            values: initialData.data,
            labels: initialData.labels,
            text: initialData.total,
          },
        ];
      } else {
        // this isn't in endpoint data format so just pass it through
        doughnutData = initialData;
      }
    }
    return doughnutData;
  };

  /**
   *
   */
  getDemoData = (): Partial<PlotData>[] => {
    let dataSets: Partial<PlotData>[] = [
      {
        values: [27, 11, 25, 8, 1, 3, 25],
        marker: {},
        labels: [
          "US",
          "China",
          "European Union",
          "Russian Federation",
          "Brazil",
          "India",
          "Rest of World",
        ],
        hoverinfo: "text",
        textposition: "inside",
        hole: 0.3,
        type: "pie",
      },
    ];

    return dataSets;
  };

  /**
   * First, checks if data param is valid and if so, sets it in component's
   * state; otherwise checks if endpoint param is valid and if so, makes a call
   * to retrieve data from the endpoint and sets it in component's state after
   * transforming and cleansing it; otherwise if neither param is valid,
   * returns hardcoded demo data
   *
   * @param {any} data -
   * @param {string | null | undefined} endpoint -
   * @returns {void}
   */
  applyData = (data: any, endpoint: string | null | undefined): void => {
    if (data) {
      this.transformInitialData(data);
    } else if (endpoint) {
      // check if there is an endpoint
      // make axios call and set data in state
      axios
        .get(endpoint)
        .then((response: any) => {
          this.transformInitialData(response.data);
        })
        .catch((error) => {
          console.error("ERROR | ", error);
        });
    } else {
      if (this._isMounted) {
        this.setState({
          ...this.state,
          data: this.getDemoData(),
          total: "CO2",
        });
      }
    }
  };

  /**
   *
   */
  getLayout = (): Partial<Layout> => {
    let text = "";
    if (this.state.total) {
      text = this.state.total.toString();
    }

    let layout: Partial<Layout> = {
      ...this._coreLayout,
      showlegend: true,
      legend: { ...this._coreXLegend },
      grid: { rows: 1, columns: 1 },
      annotations: [
        {
          font: {
            size: 14,
          },
          showarrow: false,
          text: text,
          x: 0.5,
          y: 0.5,
        },
      ],
    };
    return layout;
  };

  /**
   *
   */
  getData = (): Partial<PlotData>[] => {
    let dataSets: Partial<PlotData>[] = clonedeep(this.state.data);
    // apply label translations
    let labelsToTranslate: any;
    let translatedLabels: string[] = [];
    if (this.props.data) {
      for (const property in this.props.data) {
        if (property === "labels") {
          labelsToTranslate = this.props.data[property];
        }
      }
    }
    if (labelsToTranslate) {
      translatedLabels = labelsToTranslate.map((label: any) => {
        return this.props.t(label);
      });
    }
    if (dataSets && dataSets.length) {
      dataSets.forEach((dataSet) => {
        if (dataSet.labels) {
          dataSet.labels = translatedLabels;
        }
      });
    }
    // ensure marker theme is up to date
    return this.getColorsForData(dataSets, this.state.theme);
  };

  /**
   * iterates through data and apply current theme's color palette to markers
   *
   * @returns data collection with theme colors
   */
  getColorsForData = (
    dataSets: Partial<PlotData>[],
    theme: CustomTheme
  ): Partial<PlotData>[] => {
    let colors: string[] = [];
    if (dataSets && dataSets.length) {
      dataSets.forEach((dataSet: Partial<PlotData>) => {
        if (this.props.colors && dataSet.values) {
          colors = this.props.colors.slice(0, dataSet.values.length);
        } else if (theme.chartPalette && dataSet.values) {
          colors = this.props.theme.chartPalette.slice(
            0,
            dataSet.values.length
          );
        }
        if (dataSet.marker) {
          // assert the specific type otherwise it won't find the property
          dataSet.marker = dataSet.marker as Partial<PlotMarker>;
          dataSet.marker.colors = colors;
        }
      });
    }
    return dataSets;
  };

  render = () => {
    let renderData = this.getData();

    if (renderData && renderData.length) {
      const chartOmniViewProps: ChartOmniViewProps = {
        testAttribute: "component-chart-doughnut",
        data: renderData,
        layout: this.getLayout(),
        labels: this.props.labels!,
        theme: this.state.theme,
        chartViewType: ChartViewType.doughnut,
      };
      return createElement(ChartOmniView, { ...chartOmniViewProps });
    } else {
      return createElement(
        "div",
        { className: "chart-no-data" },
        "No chart data available."
      );
    }
  };
}

export default withTranslation()(ChartDoughnut);
