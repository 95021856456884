// Copyright 2021 SeekOps Inc.
// react
import { Suspense, useCallback, useContext, useEffect, useState } from "react";

// third-party
import getTheme from "./config/theme"; // default theme
import { Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { useAccount, useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";

// first-party
import i18n from "./config/i18n";
import "./App.scss";
import AppBar from "./components/AppBar/AppBar";
import Authentication from "./components/Authentication";
import i18next from "i18next";
import { NavigationTabs } from "./components/Navigation/NavigationTabs.enum";
import { AbilityContext } from "./Can";
import { RootState } from "./store";
import useAuthentication from "./components/Authentication/useAuthentication";
import { equals } from "lodash/fp";

import AsyncSites from "./components/Sites";
import AsyncNotFound from "./components/NotFound";
import AsyncReport from "./components/Report";
import AsyncSurveys from "./components/Surveys";
import AsyncProjects from "./components/Projects";
import AsyncSurvey from "./components/SurveyRoute";
import AsyncOverview from "./components/Overview";
import AsyncImporter from "./components/Importer";
import AsyncFluxPlane from "./components/FluxPlane";
import AsyncHelp from "./components/Help";
import AsyncLicense from "./components/License";
import AsyncAbout from "./components/About";
import AsyncPrivacy from "./components/PrivacyPolicy";
import AsyncPreQA from "./components/SurveyQualityAssurance";
import AsyncPostQA from "./components/SurveyPostQA/SurveySelection";
import AsyncNavigation from "./components/Navigation";
import AsyncInactivityMonitor from "./components/AuthenticationMonitor";
import AsyncReduxNotification from "./components/Notification/WithRedux";

const navigationTabs = [
  //TODO: When a tab is not authorize it will not appear
  //however it will still be accessible and throws error
  /* 
  Material-UI: the value provided `/fluxplane` to the Tabs component is invalid.
  None of the Tabs children have this value.
  You can provide on e of the following values: /surveys, /sites, /overview, /import.
  */
  {
    label: "Surveys",
    path: "/" + NavigationTabs.Surveys,
  },
  {
    label: "Sites",
    path: "/" + NavigationTabs.Sites,
  },
  {
    label: "Overview",
    path: "/" + NavigationTabs.Overview,
  },
  {
    label: "Import",
    path: "/" + NavigationTabs.Import,
  },
  {
    label: "Fluxplane",
    path: "/" + NavigationTabs.Fluxplane,
  },
  {
    label: "Projects",
    path: "/" + NavigationTabs.Projects,
  },
  {
    label: "survey_quality_assurance",
    path: "/" + NavigationTabs.SurveyQuality,
  },
  {
    label: "surveys_post_quality_assurance",
    path: "/" + NavigationTabs.SurveyPostQA,
  },
  {
    label: "Report",
    path: "/" + NavigationTabs.Report,
  },
];

const AppContent = (props: any) => {
  const abilities = useContext(AbilityContext);
  const dispatch = useDispatch();

  const { authenticate } = useAuthentication();
  const isLicenseAccepted = useSelector(
    (state: RootState) => state.authentication.eulaAccepted
  );
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const locale = useSelector((state: RootState) => state.language.locale);
  const theme = useSelector((state: RootState) => state.theme.theme);
  const token = useSelector((state: RootState) => state.authentication.token);
  const currentMSALToken = useSelector(
    (state: RootState) => state.authentication.MSALAccessToken
  );
  const [MSALToken, setMSALToken] = useState<string>("");

  // dispatch to store in redux & localstorage
  const storeMSALToken = useCallback(
    (MSALToken: string) => {
      dispatch({
        type: "@@authentication/SET_MSAL_ACCESS_TOKEN",
        MSALAccessToken: MSALToken,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (account) {
      instance
        .acquireTokenSilent({
          scopes: ["User.Read"],
          account: account,
        })
        .then((response) => {
          if (response) {
            // store the token
            storeMSALToken(response.accessToken);
          }
        });
    }
  }, [account, instance, storeMSALToken]);

  useEffect(() => {
    if (currentMSALToken && !equals(currentMSALToken, MSALToken)) {
      authenticate();
      setMSALToken(currentMSALToken);
    }
  }, [currentMSALToken, setMSALToken, MSALToken, authenticate]);

  useEffect(() => {
    let previouslySelectedLanguage = locale;
    if (previouslySelectedLanguage) {
      i18n.changeLanguage(previouslySelectedLanguage);
      // set language direction on DOM
      document.body.dir = i18next.dir(previouslySelectedLanguage);
    }
  }, [locale]);

  // users that are not authenticated in can only see the sign-in page and any deep
  // linked paths will simply redirect to the root route (sign-in page)
  let routes: JSX.Element = <div></div>;

  // when the user is not authenticated only allow path: /
  if (!token) {
    routes = (
      <Routes>
        <Route key={"authenticate"} path="/" element={<Authentication />} />
        <Route key={"default"} path="*" element={<Navigate replace to="/" />} />
      </Routes>
    );
  }

  if (token && isLicenseAccepted === true) {
    let redirect: string = "/";
    let storedRedirect = window.localStorage.getItem("passThroughPath");
    if (storedRedirect) {
      redirect = storedRedirect;
    } else {
      if (navigationTabs && navigationTabs.length) {
        // devaults to the first navigation tab
        redirect = navigationTabs[0].path;
      }
    }

    routes = (
      <Routes>
        <Route
          path="/help"
          element={
            <Suspense fallback={<>Loading Help...</>}>
              <AsyncHelp />
            </Suspense>
          }
        />
        <Route
          path="/about"
          element={
            <Suspense fallback={<>Loading About...</>}>
              <AsyncAbout />
            </Suspense>
          }
        />
        <Route
          path="/privacy"
          element={
            <Suspense fallback={<>Loading Privacy...</>}>
              <AsyncPrivacy />
            </Suspense>
          }
        />
        {
          /** survey report POST quality assurance */
          abilities.can("read", NavigationTabs.SurveyPostQA) && (
            <Route
              path={"/" + NavigationTabs.SurveyPostQA + "/"}
              element={
                <Suspense fallback={<>Loading Post QA...</>}>
                  <AsyncPostQA />
                </Suspense>
              }
            />
          )
        }

        {
          /**Surveys */
          abilities.can("read", NavigationTabs.Surveys) && (
            <Route
              path={"/" + NavigationTabs.Surveys}
              element={
                <Suspense fallback={<>Loading Surveys...</>}>
                  <AsyncSurveys />
                </Suspense>
              }
            />
          )
        }
        {abilities.can("read", NavigationTabs.Surveys) && (
          <Route
            path={"/survey/:id"}
            element={
              <Suspense fallback={<>Loading Survey...</>}>
                <AsyncSurvey />
              </Suspense>
            }
          />
        )}
        {
          /** projects */
          true && (
            <Route
              path={"/" + NavigationTabs.Projects}
              element={
                <Suspense fallback={<>Loading Projects...</>}>
                  <AsyncProjects />
                </Suspense>
              }
            />
          )
        }
        {
          /**Sites */
          abilities.can("read", NavigationTabs.Sites) && (
            <Route
              path={"/" + NavigationTabs.Sites}
              element={
                <Suspense fallback={<>Loading Sites...</>}>
                  <AsyncSites />
                </Suspense>
              }
            />
          )
        }
        {
          /**Sites */
          abilities.can("read", NavigationTabs.Sites) && (
            <Route
              path={"/" + NavigationTabs.Sites + "/:name"}
              element={
                <Suspense fallback={<>Loading Sites...</>}>
                  <AsyncSites />
                </Suspense>
              }
            />
          )
        }
        {
          /**Overview */
          abilities.can("read", NavigationTabs.Overview) && (
            <Route
              path={"/" + NavigationTabs.Overview}
              element={
                <Suspense fallback={<>Loading Overview...</>}>
                  <AsyncOverview />
                </Suspense>
              }
            />
          )
        }
        {
          /**Import */
          abilities.can("read", NavigationTabs.Import) && (
            <Route
              path={"/" + NavigationTabs.Import}
              element={
                <Suspense fallback={<>Loading Importer...</>}>
                  <AsyncImporter />
                </Suspense>
              }
            />
          )
        }

        {
          /**Fluxplane */
          abilities.can("read", NavigationTabs.Fluxplane) && (
            <Route
              path={"/" + NavigationTabs.Fluxplane}
              element={
                <Suspense fallback={<>Loading Fluxplane...</>}>
                  <AsyncFluxPlane />
                </Suspense>
              }
            >
              <Route
                path={":id"}
                element={
                  <Suspense fallback={<>Loading Fluxplane...</>}>
                    <AsyncFluxPlane />
                  </Suspense>
                }
              />
            </Route>
          )
        }
        {
          /** survey report quality assurance */
          abilities.can("read", NavigationTabs.SurveyQuality) && (
            <Route
              path={"/" + NavigationTabs.SurveyQuality + "/"}
              element={
                <Suspense fallback={<>Loading Pre QA...</>}>
                  <AsyncPreQA />
                </Suspense>
              }
            />
          )
        }
        {
          /**Report */
          abilities.can("read", NavigationTabs.Report) && (
            <Route
              path={"/" + NavigationTabs.Report + "/"}
              element={
                <Suspense fallback={<>Loading Report...</>}>
                  <AsyncReport />
                </Suspense>
              }
            />
          )
        }
        <Route
          path="/404"
          element={
            <Suspense fallback={<>Loading Not Found...</>}>
              <AsyncNotFound />
            </Suspense>
          }
        />
        <Route path="*" element={<Navigate replace to={redirect} />} />
      </Routes>
    );
  }

  return (
    <ThemeProvider theme={theme || getTheme("light")}>
      <CssBaseline />
      <div className="App" aria-label="app-content">
        {token && !isLicenseAccepted && (
          <>
            <Suspense fallback={<>Loading EULA...</>}>
              <AsyncLicense />
            </Suspense>
          </>
        )}

        {token && isLicenseAccepted === true && (
          <section className="header no-print">
            <AppBar isAuthenticated={token !== null} />
            <Suspense fallback={<>Loading Navigation...</>}>
              <AsyncNavigation tabs={navigationTabs} />
            </Suspense>
          </section>
        )}
        <main>
          <Suspense fallback={<div>loading...</div>}>{routes}</Suspense>
          <Suspense fallback={<>Loading Notification...</>}>
            <AsyncReduxNotification />
          </Suspense>
          <Suspense fallback={<>Loading Inactivity Monitor...</>}>
            <AsyncInactivityMonitor />
          </Suspense>
        </main>
      </div>
    </ThemeProvider>
  );
};

export default AppContent;
