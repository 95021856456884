// Copyright 2021 SeekOps Inc.
import axios from "../../AJAX";
import { getBaseURL } from "../../global";
import { FluxplaneRunLayerStatus } from "../GeoMap/GeoMap.interfaces";

/**
 *
 * @param surveyId
 */
export const getFluxplaneRuns = (surveyId: number) => {
  return axios.get(
    getBaseURL() + "/api/surveys/" + surveyId + "/fluxplaneruns/"
  );
};

/**
 *
 * @param fluxplaneRuns
 */
export const sortFluxplaneRuns = (
  fluxplaneRuns: any[]
): FluxplaneRunLayerStatus[] => {
  // populate the fluxplaneRunLayerStatuses
  let fluxplaneRunLayerStatuses: FluxplaneRunLayerStatus[] = [];

  if (fluxplaneRuns && fluxplaneRuns.length) {
    fluxplaneRuns.forEach((fluxplanerun: any) => {
      fluxplaneRunLayerStatuses.push({
        fluxplaneRunId: fluxplanerun.id,
        configuration: fluxplanerun.fluxplane_configuration,
        visible: true,
        isPrimary: fluxplanerun.primary,
        details: fluxplanerun?.details || {},
        trims: fluxplanerun?.trims || [],
      });
    });

    // sort the fluxplane statuses by primary first then in descending
    // numerical order
    fluxplaneRunLayerStatuses = fluxplaneRunLayerStatuses.sort((a, b) => {
      if (a.isPrimary === true && b.isPrimary === false) {
        return -1;
      } else if (a.isPrimary === false && b.isPrimary === true) {
        return 1;
      } else {
        if (a.fluxplaneRunId > b.fluxplaneRunId) {
          return -1;
        } else if (a.fluxplaneRunId < b.fluxplaneRunId) {
          return 1;
        } else {
          return 0;
        }
      }
    });
  }

  return fluxplaneRunLayerStatuses;
};
