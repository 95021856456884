// Copyright 2021 SeekOps Inc.
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// first party
import axiosInstance from "../../AJAX";
import { AuthenticationInitialPayload } from "./Authentication.interfaces";
import preferencesActionTypes, {
  emissionsPreferences,
  flightpathPreferences,
  UserUnitsITF,
} from "../../store/preferences/preferences.types";
import getTheme, { ThemeType } from "../../config/theme";
import { AbilityContext } from "../../Can";
import { RootState } from "../../store";

const useAuthentication = () => {
  const dispatch = useDispatch();
  const abilitiesContext = useContext(AbilityContext);
  const [hasError, setHasError] = useState<boolean>(false);
  const authURL = "/token/create";
  const MSALToken = useSelector(
    (state: RootState) => state.authentication.MSALAccessToken
  );

  /**
   *
   * @param abilities
   */
  const initializeAbilities = (abilities: any) => {
    let rules = [];
    for (const ability in abilities) {
      if (abilities.hasOwnProperty(ability)) {
        const element: string[] = abilities[ability];
        rules.push({ action: element, subject: ability });
      }
    }

    abilitiesContext.update(rules);
  };

  const setAbilities = (abilities: any) => {
    dispatch({
      type: "@@authorization/SET_ABILITIES",
      abilities: abilities,
    });
  };

  /**
   * Stores authorization data (abilities) after successful sign in
   *
   * @param authorizationData response data from successful sign in (token
   * creation) which can contain abilities (permissions)
   */
  const storeAuthorizationData = (authorizationData: any) => {
    if (authorizationData?.company_role?.role?.abilities) {
      setAbilities(authorizationData.company_role.role.abilities);
    }
  };

  /**
   * Stores authentication tokens after successful sign in
   *
   * @param authenticationData response data from successful sign in (token
   * creation)
   */
  const storeAuthenticationData = (authenticationData: any) => {
    // dispatch authentication procedure
    dispatch({
      type: "@@authentication/INITIALIZE_SUCCESSFUL",
      token: authenticationData.access,
      refreshToken: authenticationData.refresh,
      refreshExpiry: authenticationData.refresh_token_expiry,
      userIDNumber: authenticationData.id,
      userID: authenticationData.username,
      userNameFirst: authenticationData.first_name,
      userNameLast: authenticationData.last_name,
      companyID: authenticationData.company_role.company.id,
      companyName: authenticationData.company_role.company.name,
      companies: authenticationData.companies
        ? authenticationData.companies
        : [
            {
              id: authenticationData.company_id,
              name: authenticationData.company,
            },
          ],
      eulaAccepted: authenticationData.eula_accepted,
    });
  };

  const setEmissionsPreferences = (
    emissionsPreferences: emissionsPreferences
  ) => {
    dispatch({
      type: preferencesActionTypes.SET_EMISSIONS_PREFERENCES,
      emissionsPreferences: emissionsPreferences,
    });
  };

  const setFlightpathPreferences = (
    flightpathPreferences: flightpathPreferences
  ) => {
    dispatch({
      type: preferencesActionTypes.SET_FLIGHTPATH_PREFERENCES,
      flightpathPreferences: flightpathPreferences,
    });
  };

  const setUserUnits = (userUnits: UserUnitsITF) => {
    dispatch({
      type: preferencesActionTypes.SET_USER_UNITS,
      userUnits: userUnits,
    });
  };

  const setThemeType = (themeType: string) => {
    document.body.className = themeType;
    dispatch({
      type: "@@theme/SET_THEME_TYPE",
      themeType: themeType,
    });
  };

  const setTheme = (themeType: string) => {
    // get the theme that corresponds to the type
    dispatch({
      type: "@@theme/SET_THEME",
      theme: getTheme(themeType),
    });
  };

  const setLocale = (newLocale: string, newDir: string) => {
    dispatch({
      type: "@@languageSelectorStore/SET_LOCALE",
      locale: newLocale,
      dir: newDir,
    });
  };

  const setSessionExpired = (isExpired: boolean) => {
    dispatch({
      type: "@@authentication/SET_SESSION_EXPIRED",
      signedOutAutomatically: isExpired,
    });
  };

  /**
   *
   * @param username
   * @param password
   */
  const authenticate = (credentials?: {
    username: string;
    password: string;
  }) => {
    // reset any previous error
    setHasError(false);
    // legacy login
    let legacyCredentials: AuthenticationInitialPayload | {} = {};
    // SSO login
    let SSOCredentials: { azure_token: string } | {} = {};
    if (MSALToken) {
      SSOCredentials = { azure_token: MSALToken };
    } else if (credentials) {
      legacyCredentials = {
        username: credentials.username,
        password: credentials.password,
      };
    }

    const payload = { ...legacyCredentials, ...SSOCredentials };

    axiosInstance
      .post(authURL, payload)
      .then((response: any) => {
        initializeAbilities(response.data.company_role.role.abilities);
        const authHeader = `Bearer ${response.data.access}`;
        if (authHeader) {
          // update the headers in axios instance for subsequent requests
          axiosInstance.defaults.headers.common["Authorization"] = authHeader;
          localStorage.setItem("JWT", authHeader);
        }
        storeAuthenticationData(response.data);
        storeAuthorizationData(response.data);

        let preferences;
        let emissionsPreferences: emissionsPreferences;
        let flightpathPreferences: flightpathPreferences;
        if (response?.data?.company_role?.preferences) {
          preferences = response.data.company_role.preferences;
          if (preferences?.emissions) {
            emissionsPreferences = preferences.emissions;
            setEmissionsPreferences(emissionsPreferences);
          }
          if (preferences?.flightPath) {
            flightpathPreferences = preferences.flightPath;
            setFlightpathPreferences(flightpathPreferences);
          }
          // store user units
          if (preferences?.unit_system && preferences?.emissions?.unit) {
            const userUnits: UserUnitsITF = {
              systemUnit: preferences.unit_system,
              flowRateUnit: preferences.emissions.unit,
            };
            setUserUnits(userUnits);
          }

          if (preferences?.locale) {
            setLocale(preferences.locale, document.body.dir);
          }

          if (preferences?.dark_mode && preferences.dark_mode === true) {
            setTheme(ThemeType.dark);
            setThemeType(ThemeType.dark);
          } else {
            setTheme(ThemeType.light);
            setThemeType(ThemeType.light);
          }
        }

        // session is now valid
        setSessionExpired(false);

        // make sure that passThrough is reset regardless if a passthrough was  used
        window.localStorage.removeItem("passThroughPath");
      })
      .catch((error: any) => {
        console.error("useAuthentication | ERROR", error);
        setHasError(true);
      });
  };

  return {
    authenticate,
    hasError,
  };
};

export default useAuthentication;
