// Copyright 2021 SeekOps Inc.
/* 
Component’s file index.js

This allows to import it via src/components/component-name, despite
being a directory. When importing a directory, the index.ts 
file is imported. 

Only the top-most component should be exported here.
*/
import Authentication from "./Authentication";
export default Authentication;