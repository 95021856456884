// Copyright 2021 SeekOps Inc.
// third-party
import { Reducer } from "redux";
import { NotificationType } from "../../components/Notification/NotificationTypes.enum";
import { FlightType } from "../../components/SurveyQualityAssurance/SurveyFlightType";
import { QualityAssuranceStep } from "../../components/SurveyQualityAssurance/SurveyQualityAssurance.interfaces";

// first-party
import QualityAssuranceActionTypes, {
  QualityAssuranceState,
} from "./qualityAssurance.types";

// Type-safe initialState!
export const initialQualityAssuranceState: QualityAssuranceState = {
  currentStep: QualityAssuranceStep.SurveySelection,
  surveyIDForReview: -1,
  surveySitesForReview: "",
  surveyTimeForReview: "",
  surveyDATForReview: "",
  surveyForReviewStatus: NotificationType.default,
  validityChecklistComplete: false,
  fluxplaneIntervals: null,
  notes: "",
  showSelection: false,
  isProcessingFinished: false,
  uploadPhoto: false,
  isUploadPhotoDisabled: true,
  flightType: FlightType.fluxplane,
  normalizeAltitude: true,
};

/**
 * set the abilities to whatever was in the local storage if needed.
 * Local storage is cleared whenever a log out occurs
 */
const getInitialPreferencesState = (): QualityAssuranceState => {
  let state: QualityAssuranceState = initialQualityAssuranceState;
  return state;
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings
// on the reducer side, everything will remain type-safe.
const qualityAssuranceReducer: Reducer<QualityAssuranceState> = (
  state = getInitialPreferencesState(),
  action
) => {
  switch (action.type) {
    case QualityAssuranceActionTypes.SET_CURRENT_STEP: {
      return { ...state, currentStep: action.currentStep };
    }
    case QualityAssuranceActionTypes.SET_SURVEY_ID_FOR_REVIEW: {
      return { ...state, surveyIDForReview: action.surveyIDForReview };
    }
    case QualityAssuranceActionTypes.SET_FLUXPLANE_INTERVALS: {
      return { ...state, fluxplaneIntervals: action.fluxplaneIntervals };
    }
    case QualityAssuranceActionTypes.SET_NOTES: {
      return { ...state, notes: action.notes };
    }
    case QualityAssuranceActionTypes.SET_SURVEY_FOR_REVIEW: {
      return {
        ...state,
        surveyIDForReview: action.surveyIDForReview,
        surveySitesForReview: action.surveySitesForReview,
        surveyTimeForReview: action.surveyTimeForReview,
        surveyDATForReview: action.surveyDATForReview,
        flightType: action.flightType,
        normalizeAltitude: action.normalizeAltitude,
      };
    }
    case QualityAssuranceActionTypes.SET_SURVEY_DAT_FOR_REVIEW: {
      return {
        ...state,
        surveyDATForReview: action.surveyDATForReview,
      };
    }
    case QualityAssuranceActionTypes.SET_SURVEY_FOR_REVIEW_STATUS: {
      return { ...state, surveyForReviewStatus: action.surveyForReviewStatus };
    }
    case QualityAssuranceActionTypes.RESET: {
      return getInitialPreferencesState();
    }
    case QualityAssuranceActionTypes.SET_SHOW_SELECTION: {
      return { ...state, showSelection: action.showSelection };
    }
    case QualityAssuranceActionTypes.SET_IS_PROCESSING_FINISHED: {
      return { ...state, isProcessingFinished: action.isProcessingFinished };
    }
    case QualityAssuranceActionTypes.SET_VALIDITY_CHECKLIST_COMPLETE: {
      return {
        ...state,
        validityChecklistComplete: action.validityChecklistComplete,
      };
    }
    case QualityAssuranceActionTypes.SET_UPLOAD_PHOTO: {
      return { ...state, uploadPhoto: action.uploadPhoto };
    }
    case QualityAssuranceActionTypes.SET_IS_UPLOAD_PHOTO_DISABLED: {
      return { ...state, isUploadPhotoDisabled: action.isUploadPhotoDisabled };
    }
    case QualityAssuranceActionTypes.SET_FLIGHT_TYPE: {
      return { ...state, flightType: action.flightType };
    }
    case QualityAssuranceActionTypes.SET_NORMALIZE_ALTITUDE: {
      return { ...state, normalizeAltitude: action.normalizeAltitude };
    }
    default:
      return state;
  }
};

// Instead of using default export, we use named exports. That way we can group
// these exports inside the `index.js` folder.
export default qualityAssuranceReducer;
