// Copyright 2021 SeekOps Inc.
/**
 *
 */
export enum ChartViewType {
  scatter = "scatter",
  polar = "polar",
  bar = "bar",
  doughnut = "doughnut",
  scattersWithSlider = "scatters+slider",
}
