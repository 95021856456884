// Copyright 2021 SeekOps Inc.
// react
import { useCallback, useEffect, useState, createElement } from "react";

// third party
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

// first party
import axiosInstance from "../../AJAX";
import Table from "../UI/Table/Table";
import SurveyDetails from "./SurveyDetails";
import PostQASummary from "./PostQASummary";
import FPRStatuses from "./FPRStatuses";
import EN_US from "./ResourceBundles/en_US.json";
import "./SurveySelection.scss";
import { Action } from "@material-table/core";

i18next.addResourceBundle("en-US", "translation", EN_US);

const SurveySelection = (props: any) => {
  type SelectedSurveyId = number | null;

  const { t } = useTranslation();

  interface SurveyPostQAState {
    surveys: any[];
    initialSelectedSurveyId: SelectedSurveyId;
    initialConcentrationData: number[];
    initialAltitudeData: number[];
    initialTimestampData: string[];
    initialSelectedSurveyDAT: string;
    initialSelectedSurveyStartTime: string;
    initialTotalSurveyCount: number;
    initialTotalFailedSurveyCount: number;
    initialSelectedSurveyStatus: number;
  }

  const initialState: SurveyPostQAState = {
    surveys: [],
    initialSelectedSurveyId: null,
    initialConcentrationData: [],
    initialAltitudeData: [],
    initialTimestampData: [],
    initialSelectedSurveyDAT: "",
    initialSelectedSurveyStartTime: "",
    initialTotalSurveyCount: 0,
    initialTotalFailedSurveyCount: 0,
    initialSelectedSurveyStatus: 2,
  };

  // state assignments
  const [postQASurveys, setPostQASurveys] = useState(initialState.surveys);
  const [selectedSurveyId, setSelectedSurveyId] = useState(
    initialState.initialSelectedSurveyId
  );
  const [selectedSurveyDAT, setSelectedSurveyDAT] = useState(
    initialState.initialSelectedSurveyDAT
  );
  const [selectedSurveyStartTime, setSelectedSurveyStartTime] = useState(
    initialState.initialSelectedSurveyStartTime
  );
  const [selectedSurveyStatus, setSelectedSurveyStatus] = useState(
    initialState.initialSelectedSurveyStatus
  );
  const [concentrationData, setConcentrationData] = useState(
    initialState.initialConcentrationData
  );
  const [altitudeData, setAltitudeData] = useState(
    initialState.initialAltitudeData
  );
  const [timestampData, setTimestampData] = useState(
    initialState.initialTimestampData
  );
  const [totalSurveyCount, setTotalSurveyCount] = useState(
    initialState.initialTotalSurveyCount
  );
  const [totalFailedSurveyCount, setTotalFailedSurveyCount] = useState(
    initialState.initialTotalFailedSurveyCount
  );
  const [timeOfLastReview, setTimeOfLastReview] = useState<number>(0);

  const tableConfig = {
    editable: false,
    pageSize: 15,
    pageSizeOptions: [15, 50, 100],
  };

  /**
   *
   */
  const setSurveys = useCallback(() => {
    axiosInstance
      .get("/api/surveytables/?mode=post_qa")
      .then((response: any) => {
        setPostQASurveys(response.data);
      });
  }, []);

  /**
   *
   */
  const setStats = useCallback((surveys: any[]) => {
    if (surveys && surveys.length) {
      setTotalSurveyCount(surveys.length);
      let failedSurveys = surveys.filter((survey) => {
        if (
          survey &&
          survey.post_qa_status &&
          survey.post_qa_status?.value === 0
        ) {
          return true;
        }
        return false;
      });
      setTotalFailedSurveyCount(failedSurveys.length);
    }
  }, []);

  /**
   *
   */
  const setPlotData = useCallback((surveyId: SelectedSurveyId) => {
    if (surveyId) {
      axiosInstance
        .get(
          `/api/surveys/${surveyId}/geojsonmeasurements/?altitude=lidar&max_points=5000`
        )
        .then((response: any) => {
          setConcentrationData(
            response?.data?.features.map((feature: any) => {
              return feature.properties.concentration_ppm;
            })
          );
          setAltitudeData(
            response?.data?.features.map((feature: any) => {
              return feature.properties.lidar_altitude;
            })
          );
          setTimestampData(
            response?.data?.features.map((feature: any) => {
              return feature.properties.timestamp;
            })
          );
        });
    }
  }, []);

  useEffect(() => {
    if (props.unapprovedSurveys) {
      setPostQASurveys(props.unapprovedSurveys);
    } else {
      setSurveys();
    }
  }, [props.unapprovedSurveys, setSurveys, timeOfLastReview]);

  // handle when the survey collection changes
  useEffect(() => {
    setStats(postQASurveys);
  }, [postQASurveys, setStats]);

  useEffect(() => {
    if (selectedSurveyId !== null) {
      setPlotData(selectedSurveyId);
    }
  }, [selectedSurveyId, setPlotData]);

  /*
   * helper methods
   */

  /**
   *
   * @param data
   * @returns
   */
  const getStatusColumn = (data: any) => {
    let key = "post_qa_status";
    let messageLabel = data[key]
      ? data[key].labelkey
      : "msg.survey.post_qa_status";
    return {
      title: t(messageLabel),
      field: key,
      filtering: true,
      sorting: true,
      customFilterAndSearch: (term: string, rowData: any) => {
        // store results if any
        let results = [];
        // filter site names (case insensitive)
        results = rowData.sites.filter((site: { name: string; id: number }) => {
          return site.name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
        });
        // return boolean based on if results were found
        return results.length;
      },
      render: (rowData: any) => {
        let className = "";
        let label = "";
        let status = 2; // pending by default

        if (rowData?.post_qa_status === 0) {
          status = 0;
        } else {
          status = rowData?.post_qa_status;
        }
        switch (status) {
          case 0:
            className = "status-failed";
            label = "msg.post-qa.status-failed";
            break;

          case 1:
            className = "status-passed";
            label = "msg.post-qa.status-passed";
            break;

          case 2:
            className = "status-pending";
            label = "msg.post-qa.status-pending";
            break;
          default:
            break;
        }

        return <span className={className}>{t(label)}</span>;
      },
    };
  };

  /**
   *
   *
   * @param data
   * @returns
   */
  const getFPRStatusColumn = (data: any) => {
    return {
      title: t("msg.post-qa.fpr-statuses"),
      filtering: false,
      sorting: false,
      render: (rowData: any) => {
        let totalFPRs = 0;
        let fluxplaneRejectionCount = 0;
        // make sure there is data
        if (rowData) {
          // make sure the count has a value
          if (rowData.fluxplanerun_count) {
            totalFPRs = rowData.fluxplanerun_count;
          }
          // make sure rejection count has a value
          if (rowData.fluxplanerun_rejections) {
            fluxplaneRejectionCount = rowData.fluxplanerun_rejections;
          }
        }
        return (
          <FPRStatuses
            totalFPRs={totalFPRs}
            failedFPRs={fluxplaneRejectionCount}
          />
        );
      },
    };
  };

  /**
   * Defines the format of the table columns by formatting given response data
   * into survey column data
   *
   * @param data - survey endpoint response
   * @returns collection of survey columns
   */
  const getSurveyColumns = (data: any) => {
    let tableColumns: any[] = [];

    if (data && data.length) {
      tableColumns.push(getStatusColumn(data[0]));
      tableColumns.push(getFPRStatusColumn(data[0]));
      Object.keys(data[0]).map((key: any) => {
        let column: any;

        switch (key) {
          case "projects":
            column = {
              title: t(data[0][key].labelkey),
              field: key,
              render: (rowData: any) => {
                return (
                  <span style={{ whiteSpace: "nowrap" }}>
                    {rowData?.projects?.name}
                  </span>
                );
              },
            };
            break;
          case "id":
            column = {
              title: t(data[0][key].labelkey),
              field: key,
              render: (rowData: any) => {
                return (
                  <span style={{ whiteSpace: "nowrap" }}>{rowData.id}</span>
                );
              },
            };
            break;
          case "sites":
            column = {
              title: t(data[0][key].labelkey),
              field: key,
              filtering: true,
              sorting: false,
              customFilterAndSearch: (term: string, rowData: any) => {
                // store results if any
                let results = [];
                // filter site names (case insensitive)
                results = rowData.sites.filter(
                  (site: { name: string; id: number }) => {
                    return (
                      site.name.toLowerCase().indexOf(term.toLowerCase()) !== -1
                    );
                  }
                );
                // return boolean based on if results were found
                return results.length;
              },
              render: (rowData: any) => {
                return rowData.sites
                  .map((site: any) => {
                    return site.name;
                  })
                  .join(", ");
              },
            };
            break;
          case "start_time":
            column = {
              title: t(data[0][key].labelkey),
              field: key,
              defaultSort: "desc",
              render: (rowData: any) => {
                return (
                  <span style={{ whiteSpace: "nowrap" }}>
                    {rowData.start_time}
                  </span>
                );
              },
            };
            break;
          case "data_source":
            column = {
              title: t(data[0][key].labelkey),
              field: key,
              render: (rowData: any) => {
                return (
                  <span style={{ whiteSpace: "nowrap" }}>
                    {rowData.data_source}
                  </span>
                );
              },
            };
            break;
          default:
            column = {
              title: t(data[0][key].labelkey),
              field: key,
              render: (rowData: any) => {
                return null;
              },
              hidden: true,
            };
            break;
        }
        return tableColumns.push(column);
      });
    }

    return formatSurveyColumns(tableColumns);
  };

  /**
   * filters and sorts columns
   *
   * @param tableColumns
   */
  const formatSurveyColumns = (tableColumns: any[]): any[] => {
    let formattedColumns: any[] = [];
    if (tableColumns) {
      formattedColumns = tableColumns.filter((column) => {
        return column.hidden !== true;
      });
      formattedColumns = formattedColumns.sort((a: any, b: any) => {
        if (a.field === "sites" && b.field === "data_source") {
          return -1;
        } else if (a.field === "start_time" && b.field === "data_source") {
          return -1;
        } else if (a.field === "start_time" && b.field === "sites") {
          return -1;
        } else {
          return 0;
        }
      });
    }
    return formattedColumns;
  };

  /**
   * Formats response data into table row format and returns the collection
   *
   * @param data - response data from surveys endpoint
   * @returns collection of survey data in table row format
   */
  const getSurveyRows = (data: any) => {
    let tableRows: any[] = [];
    if (data && data.length) {
      data.map((row: any) => {
        // don't use deleted survey records
        if (row?.is_deleted.value === false) {
          return tableRows.push({
            sites: row.sites.value,
            projects: row?.projects?.value[0],
            start_time: row.start_time.value,
            data_source: row.data_source.value,
            id: row.id.value,
            post_qa_status: row.post_qa_status.value,
            fluxplanerun_rejections: row.fluxplanerun_rejections.value,
            fluxplanerun_count: row.fluxplanerun_count.value,
            // this row data used by Table component to disable action
            disabled: false,
          });
        } else {
          return {};
        }
      });
    }
    return tableRows;
  };

  /**
   *
   *
   */
  const getTableActions = (): Action<any>[] => {
    let tooltip = t("msg.post-qa.begin-review");
    let actions: any[] = [
      {
        icon: createElement(PlayCircleOutlineIcon, {
          "aria-label": "post-qa-review-action",
        }),
        tooltip: tooltip,
        onClick: (event: any, rowData: any) => {
          setSelectedSurveyId(rowData.id);
          setSelectedSurveyDAT(rowData.data_source);
          setSelectedSurveyStartTime(rowData.start_time);
          setSelectedSurveyStatus(rowData.post_qa_status);
        },
      },
    ];
    return actions;
  };

  return (
    <div
      className="post-qa-survey-selection"
      aria-label="post-qa-survey-selection"
    >
      <PostQASummary
        totalSurveys={totalSurveyCount}
        surveysFailed={totalFailedSurveyCount}
      />
      <Table
        tableColumns={getSurveyColumns(postQASurveys)}
        tableRows={getSurveyRows(postQASurveys)}
        title={t("msg.post-qa.select-review-survey")}
        tableconfig={tableConfig}
        tableActions={getTableActions()}
      ></Table>
      {selectedSurveyId && (
        <SurveyDetails
          surveyId={selectedSurveyId}
          startTime={selectedSurveyStartTime}
          datFile={selectedSurveyDAT}
          status={selectedSurveyStatus}
          timestampData={timestampData}
          concentrationData={concentrationData}
          altitudeData={altitudeData}
          setSelectedSurveyId={setSelectedSurveyId}
          setTimeOfLastReview={setTimeOfLastReview}
        />
      )}
    </div>
  );
};

export default SurveySelection;
