// Copyright 2021 SeekOps Inc.
import { QAInfoContent } from "../../components/Surveys/Surveys.interfaces";

// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define whichever naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the

// convention of Redux's `@@INIT` action.
export enum surveysActionTypes {
  SET_INFO_OPEN = "@@surveys/SET_INFO_OPEN",
  SET_INFO_MAP = "@@surveys/SET_INFO_MAP",
  SET_INFO_CONTENT = "@@surveys/SET_INFO_CONTENT",
  SET_SURVEYS_TO_MERGE = "@@surveys/SET_SURVEYS_TO_MERGE",
}

// Declare state types with `readonly` modifier to get compile time immutability
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface surveysState {
  isMoreActionsOpen: boolean;
  isInfoOpen: boolean;
  infoMap: { [key: string]: string };
  infoContent: QAInfoContent | null;
  surveysToMerge: number[];
}

export default surveysActionTypes;
