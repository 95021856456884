// Copyright 2021 SeekOps Inc.
// react
import { Component, createElement } from "react";

// third-party
import { connect } from "react-redux";
import { Theme } from "@mui/material";
import { PlotData } from "plotly.js";

// first party
import { CustomTheme } from "../../../config/theme/theme.interfaces";
import axios from "../../../AJAX";
import ChartView from "./Chart.view";
import {
  ChartContainerProps,
  ChartContainerState,
  CustomChartOptions,
  ChartViewProps,
} from "./Chart.interfaces";
import { ChartViewType } from "./Chart.enums";
import { getCoreLayout, getCoreXLegend } from "./type/Chart.config";
import ChartBar from "./type/Chart-bar.container";
import ChartDoughnut from "./type/Chart-doughnut.container";
import { ChartProps } from "./type/ChartType.interfaces";
import ChartScatter from "./type/Chart-scatter.container";
import ChartPolar from "./type/Chart-polar.container";
import { withTranslation } from "react-i18next";

export class ChartContainer extends Component<
  ChartContainerProps,
  ChartContainerState
> {
  _isMounted = false;
  data: object = {};
  multiChartData: PlotData[][] = [];
  dataSourceURL: string = "";
  view = {};
  options: CustomChartOptions | undefined;
  materialThemeChartOptions!: any;
  materialThemeChartColors!: string[];
  scales: object = {};

  /**
   *
   * @param props
   */
  constructor(props: ChartContainerProps) {
    super(props);
    if (this.props.data) {
      this.data = this.props.data;
    }
    if (this.props.multiChartData) {
      //
      this.multiChartData = JSON.parse(
        JSON.stringify(this.props.multiChartData)
      );
    }
    // set a dynamic options object from the immutable props
    this.options = this.props.options;
    // chart view
    this.state = {
      view: null,
      data: [],
      chartViewType: props.chartViewType,
      isDrawerOpen: false,
      isOverlapping: false,
      multiChartData: [],
      originalMultiChartData: [],
      selectedPlotIndex: 0,
      valuesOfSliders: [],
      dataLength: 0,
      heading: props.heading,
      ranksOfSliders: [],
      chart: createElement("div"), // create an empty div by default
      hideActions: this.props.hideActions,
    };
  }

  /**
   * Creates an instance of a Chart component based on the type passed
   * in the props; then sets the instance in this component's state
   */
  initializeChart = (props: ChartProps) => {
    let chartType: any; // Class

    switch (this.props.chartViewType) {
      case ChartViewType.bar:
        chartType = ChartBar;
        break;

      case ChartViewType.doughnut:
        chartType = ChartDoughnut;
        break;

      case ChartViewType.scatter:
        chartType = ChartScatter;
        break;

      case ChartViewType.polar:
        chartType = ChartPolar;
        break;
    }
    if (this._isMounted) {
      this.setState({
        ...this.state,
        chart: createElement(chartType, {
          theme: props.theme,
          colors: props.colors,
          displayTitle: props.displayTitle,
          labels: props.labels,
          endpoint: props.endpoint,
          data: props.data,
          heading: props.heading,
          hideActions: props.hideActions,
          hideLegend: props.hideLegend,
        }),
      });
    }
  };

  /**
   *
   */
  componentDidMount = () => {
    this._isMounted = true;

    if (this.props.chartViewType === ChartViewType.scattersWithSlider) {
      this.initChart(this.props);
    } else {
      this.initializeChart(this.props); // newer initialization
    }

    if (this.props.multiChartData!) {
      let multiChartData: PlotData[][] = [];
      let multiChartDataOG: PlotData[][] = JSON.parse(
        JSON.stringify(this.multiChartData)
      );

      let initialLength = multiChartDataOG[0][0].x!.length;
      let valuesOfSliders: number[][] =
        this.props.sliderValues && this.props.sliderValues!.length >= 1
          ? this.props.sliderValues
          : [];

      multiChartDataOG.forEach((element, index) => {
        element.forEach((element2, index) => {
          element2 = {
            ...element2,
            marker: {
              color: "#FF4500", //ColorName Vermilion Orange
            },
            mode: element2.mode ? element2.mode : "markers",
            type: "scatter",
            showlegend: false,
          };
          element[index] = element2;
        });
        multiChartDataOG[index] = element;
      });

      let themePallette = (this.props.theme && this.props.theme.chartPalette) ? this.props.theme.chartPalette : [];
      let initializeData: PlotData = {} as PlotData;
      if (valuesOfSliders.length > 0) {
        valuesOfSliders.forEach((element, index) => {
          if (index <= valuesOfSliders.length - 1) {
            let colorIndex = multiChartData[0] ? multiChartData[0].length : 0;
            multiChartDataOG.forEach((chart, chartIndex) => {
              let y = chart[0].y.slice(...element);
              let x = chart[0].x.slice(...element);
              let chartData: PlotData = {
                ...initializeData,
                x: x,
                y: y,
                marker: {
                  color: themePallette[colorIndex],
                },
                showlegend: false,
                mode: "markers",
              } as PlotData;
              if (multiChartData[chartIndex]) {
                multiChartData[chartIndex].push(chartData);
              } else {
                multiChartData.push([chartData]);
              }
            });
          }
        });
      } else {
        let colorIndex = multiChartData[0] ? multiChartData[0].length : 0;
        multiChartDataOG.forEach((chart, chartIndex) => {
          let chartData: PlotData = {
            ...initializeData,
            marker: {
              color: themePallette[colorIndex],
            },
            showlegend: false,
            mode: "markers",
          } as PlotData;
          if (multiChartData[chartIndex]) {
            multiChartData[chartIndex].push(chartData);
          } else {
            multiChartData.push([chartData]);
          }
        });
      }

      let rankOfSliders: number[][] = Object.assign([], valuesOfSliders);

      rankOfSliders = this.convertAllSliderIndexToRank(rankOfSliders);

      this.setState(
        (prevState, props): ChartContainerState => ({
          ...prevState,
          multiChartData: multiChartData,
          originalMultiChartData: multiChartDataOG,
          valuesOfSliders: valuesOfSliders,
          ranksOfSliders: rankOfSliders,
          dataLength: initialLength,
        })
      );
    }
  };

  /**
   * When the components sees that the props were updated, check if the theme
   * was updated and re-initialize the chart to account for the different theme
   *
   * @param prevProps the props that existed prior to the update
   */
  componentDidUpdate = (prevProps: ChartContainerProps) => {
    if (
      prevProps.data !== this.props.data ||
      prevProps.heading !== this.props.heading ||
      prevProps.theme !== this.props.theme || 
      prevProps.hideLegend !== this.props.hideLegend
    ) {
      if (this.props.chartViewType === ChartViewType.scattersWithSlider) {
        // re-create the view
        this.initChart(this.props);
      } else {
        this.initializeChart(this.props);
      }
    }

    if (this.props.theme && this.props.theme.chartPalette) {
    if (prevProps.theme.chartPalette !== this.props.theme.chartPalette) {
      if (this.props.chartViewType === ChartViewType.scattersWithSlider) {
        let multiChartData: PlotData[][] = Object.assign(
          [],
          this.state.multiChartData
        );
        multiChartData.forEach((element) => {
          element = this.reDrawColors(element);
        });

        this.setState((prevState: ChartContainerState, props) => ({
          ...prevState,
          multiChartData: multiChartData,
        }));
      }
    }}
  };

  /**
   *
   */
  componentWillUnmount = () => {
    this._isMounted = false;
  };

  /**
   *
   *
   * @memberof ChartContainer
   */
  convertAllSliderIndexToRank = (sliderValues: number[][]) => {
    let rankValues: number[][] = [];
    let rankLength = this.props.indexToRank!.length - 1;
    sliderValues.forEach((slider, sliderIndex) => {
      if (!rankValues[sliderIndex]) {
        rankValues.push([]);
      }
      slider.forEach((value, index) => {
        if (!slider[index]) {
          rankValues[sliderIndex].push(0);
        }
        rankValues[sliderIndex][index] =
          this.props.indexToRank![value > rankLength ? rankLength : value];
      });
    });

    return rankValues;
  };

  /**
   * Reassigns colors based on given parameter array order
   *
   * @param data
   * @return PlotData[]
   */
  reDrawColors = (data: PlotData[]): PlotData[] => {
    data.forEach((element, index) => {
      element.marker.color = this.props.theme.chartPalette[index];
    });
    return data;
  };

  /**
   *
   * @param materialUITheme
   * @return materialThemeChartOptions
   */
  getMaterialTheme = (materialUITheme: Theme | undefined) => {
    let fontFamily: string | undefined = "Roboto";
    let fontColorPrimary: string = "green";
    let fontSize: number = 13;
    let materialThemeChartOptions: CustomChartOptions | any;

    if (materialUITheme) {
      fontFamily = materialUITheme.typography.fontFamily;
      fontColorPrimary = materialUITheme.palette.primary.main;
      fontSize = materialUITheme.typography.fontSize;
    }

    materialThemeChartOptions = {
      title: {
        display: true,
      },

      legend: {
        labels: {
          fontColor: fontColorPrimary,
          fontFamily: fontFamily,
          fontSize: fontSize,
        },
      },
      elements: {
        rectangle: {
          backgroundColor: "red",
        },
      },

      tooltips: {
        titleFontFamily: fontFamily,
        titleFontSize: fontSize,
        titleFontColor: fontColorPrimary,
      },
    };
    return materialThemeChartOptions;
  };

  /**
   *
   *
   * @param customizedMaterialTheme
   * @return chartColors
   */
  getChartColors = (customizedMaterialTheme: any) => {
    let chartColors: string[] = [];
    if (
      customizedMaterialTheme &&
      customizedMaterialTheme.hasOwnProperty("chartPalette")
    ) {
      chartColors = customizedMaterialTheme.chartPalette;
    }
    return chartColors;
  };

  /**
   *
   */
  initChart = (props: ChartContainerProps) => {
    this.materialThemeChartOptions = this.getMaterialTheme(props.theme);
    this.materialThemeChartColors = this.getChartColors(props.theme);
    this.setChartView(
      this.props.endpoint,
      this.materialThemeChartOptions,
      this.materialThemeChartColors,
      this.props.data
    );
  };

  /**
   * Creates a chart and sets it in the state
   *
   * @param dataSourceURL
   * @param chartTheme
   * @param chartColors
   */
  setChartView = (
    endpoint: string | undefined,
    chartTheme: any,
    chartColors: string[],
    data?: object
  ) => {
    if (endpoint) {
      // make call to get data
      this.getChartData(endpoint)
        .then((response: any) => {
          if (this._isMounted) {
            // only set state if mounted
            this.setState((state, props) => ({ data: response.data }));
          }
        })
        .catch((error) => {
          console.error("ERROR | ", error);
        });
    } else if (data) {
      // data was passed in directly so set it to the state
      if (this._isMounted) {
        this.setState((state, props) => ({ data: data }));
      }
    }
  };

  getLayout = (
    theme: CustomTheme,
    chartViewType: ChartViewType,
    title: string,
    hideLegend?: boolean
  ): any => {
    const coreLayout = getCoreLayout(theme, title);
    let layout: any;
    const coreXLegend = hideLegend ? {} : getCoreXLegend();

    switch (chartViewType) {
      case ChartViewType.scattersWithSlider:
        layout = {
          ...coreLayout,
          margin: {
            r: 60,
            l: 60,
            t: 60,
          },
          legend: { ...coreXLegend },
        };
        break;

      default:
        layout = {
          ...coreLayout,
          legend: { ...coreXLegend },
        };
        break;
    }

    // add y-axis props when necessary
    if (this.props.crossReferenceData && this.props.crossReferenceData.length) {
      layout.yaxis2 = {
        anchor: "x",
        overlaying: "y",
        side: "right",
        position: 0.15,
        tickfont: { color: "grey" },
      };
      // check if there is cross reference data for the selected plot
      if (this.props.crossReferenceData[this.state.selectedPlotIndex]) {
        layout.yaxis2.title =
          this.props.crossReferenceData[
            this.state.selectedPlotIndex
          ].yaxis.title;
      }
    }
    return layout;
  };

  getDataForScatter = (data: Partial<PlotData>[], theme: CustomTheme) => {
    //Iterates over all elements of array and sets appropriate properties
    data.forEach((element, index) => {
      element = {
        ...element,
        marker: {
          color: theme.chartPalette[index]
            ? theme.chartPalette[index]
            : theme.chartPalette[Math.random() * theme.chartPalette.length],
        },
        showlegend: false,
        mode: element.mode ? element.mode : "markers",
        type: "scatter",
      };
      data[index] = element;
    });
    return data;
  };

  /**
   * Returns a Partial<Plotly.Data[]>
   * Not explicitly returned as typing can mismatch due to partiality
   *
   * @memberof ChartContainer
   */
  getData = (
    theme: CustomTheme,
    chartViewType: ChartViewType,
    multiChartData?: Partial<PlotData>[][]
  ): any => {
    let data: Partial<PlotData>[] | Partial<PlotData>[][] = this.props.data!;

    switch (chartViewType) {
      case ChartViewType.scattersWithSlider:
        let multiData = multiChartData!;

        multiData.forEach((element, index) => {
          multiData[index] = this.getDataForScatter(multiData[index], theme);
        });
        data = multiData;

        break;
    }
    return data;
  };

  /**
   *
   * @param endpoint
   */
  getChartData = (endpoint: string) => {
    return axios.get(endpoint);
  };

  /**
   *
   *
   * @memberof ChartContainer
   */
  toggleDrawer = (isOpen: boolean) => {
    this.setState((prevState, props) => ({
      ...prevState,
      isDrawerOpen: isOpen,
    }));
  };

  /**
   *
   *
   * @memberof ChartContainer
   */
  handleSliderChange = (value: any[], index: number) => {
    let data: Plotly.PlotData[][] = Object.assign(
      [],
      this.state.multiChartData
    );
    let valuesOfSliders: number[][] = Object.assign(
      [],
      this.state.valuesOfSliders
    );

    let x: any;
    let y: any;
    let originalElement: PlotData[][] = JSON.parse(
      JSON.stringify(this.multiChartData)
    );

    valuesOfSliders[index] = value;

    data.forEach((element, dataIndex) => {
      x = originalElement[dataIndex][0].x!.slice(value[0], value[1]);
      y = originalElement[dataIndex][0].y!.slice(value[0], value[1]);

      data[dataIndex][index] = { ...element[index], x: x, y: y } as PlotData;
    });

    this.setState(
      (prevState: ChartContainerState, props): ChartContainerState => ({
        ...prevState,
        multiChartData: data,
        valuesOfSliders: valuesOfSliders,
      })
    );
  };

  /**
   *
   *
   * @memberof ChartContainer
   */
  handleAddSlider = () => {
    let data: Plotly.PlotData[][] = Object.assign(
      [],
      this.state.multiChartData
    );
    let valuesOfSliders: number[][] = Object.assign(
      [],
      this.state.valuesOfSliders
    );
    let themePallette = this.props.theme.chartPalette;
    let initializeData: PlotData = {} as PlotData;
    let rankSliders: number[][] = [];

    let colorIndex = data[0] ? data[0].length : 0;

    data.forEach((element, index) => {
      element.push({
        ...initializeData,
        marker: { color: themePallette[colorIndex] },
        mode: "markers",
        type: "scatter",
        showlegend: false,
      });
    });
    //Seems like the bottle neck is on the shear amount of points having to be rendered
    //Do not allow sliders to overlap, OnSliderChange have it fall on nearest

    //addedData = this.getData(this.props.theme, this.props.chartViewType, data);

    valuesOfSliders.push([0, 0]);

    rankSliders = this.convertAllSliderIndexToRank(valuesOfSliders);
    this.props.getValueOfSliders!(valuesOfSliders);
    this.props.getRankOfSliders!(rankSliders);

    this.setState(
      (prevState: ChartContainerState, props): ChartContainerState => ({
        ...prevState,
        multiChartData: data,
        valuesOfSliders: valuesOfSliders,
      })
    );
  };

  handleRadioChange = (index: number) => {
    this.setState((prevState, props) => ({
      ...prevState,
      selectedPlotIndex: index,
    }));
  };

  handleCloseSlider = (index: number) => {
    let newSliders: number[][] = Object.assign([], this.state.valuesOfSliders);
    let newData: PlotData[][] = Object.assign([], this.state.multiChartData);
    let rankSliders: number[][] = [];
    newData.forEach((element) => {
      element.splice(index, 1);
      element = this.reDrawColors(element);
    });
    newSliders.splice(index, 1);

    rankSliders = this.convertAllSliderIndexToRank(newSliders);

    this.props.getValueOfSliders!(newSliders);
    this.props.getRankOfSliders!(rankSliders);

    this.setState((prevState, props) => ({
      ...prevState,
      valuesOfSliders: newSliders,
      multiChartData: newData,
    }));
  };

  getSliderValues = (value: number[], index: number) => {
    this.handleSliderChange(value, index);
    let rankSliders: number[][] = [];
    let valuesOfSliders: number[][] = Object.assign(
      [],
      this.state.valuesOfSliders
    );

    valuesOfSliders[index] = value;
    rankSliders = this.convertAllSliderIndexToRank(valuesOfSliders);

    this.props.getValueOfSliders!(valuesOfSliders);
    this.props.getRankOfSliders!(rankSliders);
  };

  /**
   *
   */
  render = () => {
    const chartViewProps: ChartViewProps = {
      chartViewType: this.state.chartViewType,
      getLayout: this.getLayout,
      getData: this.getData,
      currentThemeType: "light",
      theme: this.props.theme,
      // apply the display name
      displayTitle: this.props.displayTitle,
      multiChartData: this.state.multiChartData,
      originalMultiChartData: this.state.originalMultiChartData,
      valuesOfSliders: this.state.valuesOfSliders,
      selectedPlotIndex: this.state.selectedPlotIndex,
      labels: this.props.labels!,
      isDrawerOpen: this.state.isDrawerOpen,
      isOverlapping: this.state.isOverlapping,
      dataLength: this.state.dataLength,
      indexToRank: this.props.indexToRank,
      toggleDrawer: this.toggleDrawer,
      handleCloseSlider: this.handleCloseSlider,
      handleRadioChange: this.handleRadioChange,
      handleSliderChange: this.handleSliderChange,
      getSliderValues: this.getSliderValues,
      handleAddSlider: this.handleAddSlider,
      hideActions: this.state.hideActions,
      mode: "preQA",
      crossReferenceData: this.props.crossReferenceData,
    };

    if (ChartViewType.scattersWithSlider === this.props.chartViewType) {
      return createElement(ChartView, { ...chartViewProps });
    } else {
      return this.state.chart;
    }
  };
}

// slice of state to pass to container through props
const mapStateToProps = (state: any) => {
  return {
    theme: state.theme.theme,
    currentThemeType: state.theme.themeType,
  };
};

const TranslatedChartContainer = withTranslation()(ChartContainer);

export default connect(mapStateToProps)(TranslatedChartContainer);
