// Copyright 2021 SeekOps Inc.
import { createElement, useRef, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router";
import axiosInstance from "./AJAX";

/**
 *
 */
export const getBaseURL = () => {
  // fallback to production
  let URL = "";

  // check if any env variables were provided
  try {
    if (process.env) {
      URL = "";
      if (process.env.REACT_APP_API_protocol) {
        URL += process.env.REACT_APP_API_protocol;
      }
      if (process.env.REACT_APP_API_host) {
        URL += process.env.REACT_APP_API_host;
      }
      if (process.env.REACT_APP_API_port) {
        URL += ":" + process.env.REACT_APP_API_port;
      }
    }
  } catch (error) {
    console.error("Error | ", error);
  }

  // if no URL was built (no env variables), fallback to creating base URL
  // from the location object
  if (!URL) {
    if (window.location) {
      URL += window.location.protocol + "//";

      URL += window.location.hostname;

      if (window.location.port) {
        URL += ":" + window.location.port;
      }
    }
  }

  // localhost defaults to prod
  if (URL.indexOf("localhost") !== -1) {
    URL = "https://soda.seekops.com";
  }
  return URL;
};

/**
 *
 * @param dataSourceName - the name of the endpoint to append to the URL.
 * includes params as normal e.g. endpointname?param1=value1&param2=value2
 */
export const getDataSourceURL = (dataSourceName: string | undefined) => {
  let URL = "";
  if (dataSourceName) {
    URL += getBaseURL() + dataSourceName;
  } else {
    throw new Error(
      'Illegal Argument Exception | Parameter "dataSourceName" must be a non-empty string'
    );
  }
  return URL;
};

/**
 *
 * @param targetName
 */
export const getPostURL = (targetName: string) => {
  let URL = "";
  if (targetName) {
    URL += getBaseURL() + targetName + "/";
  } else {
    throw new Error(
      'Illegal Argument Exception | Parameter "targetName" must be a non-empty string'
    );
  }
  return URL;
};

/**
 *
 */
export const getJWT = (): string => {
  let JWT = "" + localStorage.getItem("JWT");
  return JWT;
};

export const getJWTr = (): string => {
  let JWTr = "" + localStorage.getItem("JWTr");
  return JWTr;
};

export type TokenAction = "create" | "refresh" | "verify";

/**
 *
 * @param targetName
 */
export const getTokenURL = (action: TokenAction) => {
  return getBaseURL() + "/token/" + action + "/";
};

/**
 * allows for mounting checks within functional components
 *
 * i.e.
 * const mounted = useMountedRef();
 *
 * and checking with
 * if(mounted.current)
 * before updating the state
 *
 * @returns
 */
export const useMountedRef = () => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  return mounted;
};

/**
 * Gives wrapped component access to params, navigate, and location hooks
 *
 * @param Component
 * @returns
 */
export const withRouter = (Component: any) => {
  return (props: any) => {
    return createElement(Component, {
      ...props,
      params: useParams(),
      navigate: useNavigate(),
      location: useLocation(),
    });
  };
};

export const downloadFile = (filename: string, dataUrl: string) => {
  axiosInstance({
    url: dataUrl,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

const units = ["bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];

/**
 * convert bytes to easy to read value
 * @param bytes
 * @returns easy to read string
 */
export const niceBytes = (bytes: string): string => {
  let magnitude = 0,
    numBytes = parseInt(bytes, 10) || 0;

  while (numBytes >= 1024 && ++magnitude) {
    numBytes = numBytes / 1024;
  }

  return (
    numBytes.toFixed(numBytes < 10 && magnitude > 0 ? 1 : 0) +
    " " +
    units[magnitude]
  );
};

export const formatDate = (timestamp: any) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minutes =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const seconds =
    date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();

  return `${year}-${day}-${month} ${hours}:${minutes}:${seconds}`;
};
